<template>
  <div
    :class="['project-header', impactType, { expanded: headerExpanded }]"
    :style="{ backgroundColor: impactColorMapping[impactType] }"
  >
    <img :src="imageUrl" :alt="impactType + '_image'" class="project-header-image" />
    <div class="project-info">
      <div class="project-title">
        {{ title }}
      </div>
      <div class="read-more" @click="toggleHeaderExpanded">
        <p>{{ t('read_more') }}</p>
        <v-icon class="chevron-icon"> mdi-chevron-down </v-icon>
      </div>
    </div>
    <transition name="fade">
      <div v-if="headerExpanded" class="project-description-wrapper">
        <div class="project-description">
          {{ $t(`Projects.${projectId}.summary`) }}
        </div>
        <div class="project-description">
          {{ $t(`Projects.${projectId}.description`) }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { TranslateResult } from 'vue-i18n'
import type { ProjectId } from '@/helpers/constants'
import type { OffsetType } from '@/helpers/interfaces'
import type { Project } from '@/store/projects'
import { PROJECT_IMPACT_COLOR_MAP } from '@/helpers/projects'

export default defineComponent({
  name: 'ImpactSettingCardHeader',
  emits: ['toggleExpanded'],
  data() {
    return {
      headerExpanded: false,
      impactColorMapping: PROJECT_IMPACT_COLOR_MAP,
    }
  },
  computed: {
    imageUrl(): string | undefined {
      return this.projects.find(({ projectId }) => projectId === this.projectId)?.imageUrl
    },
    impactType(): OffsetType {
      const project = this.projects.find(({ projectId }) => projectId === this.projectId)
      if (!project) throw new Error(`project ID is invalid. - ${this.projectId}`)
      return project.type
    },
    title(): TranslateResult {
      return this.$t(`Projects.${this.projectId}.impact`)
    },
    projects(): Project[] {
      return this.$store.getters['getApprovedProjects']
    },
  },
  methods: {
    t(key: string) {
      return this.$t(`ImpactSettingCardHeader.${key}`)
    },
    toggleHeaderExpanded() {
      this.headerExpanded = !this.headerExpanded
      this.$emit('toggleExpanded', this.headerExpanded)
    },
  },
  props: {
    projectId: { type: String as PropType<ProjectId>, required: true },
    isActive: { type: Boolean, default: false },
  },
})
</script>

<style scoped lang="scss">
@import '~vuetify/settings';

.project-header {
  padding: 12px 12px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: height 0.25s ease-in-out;
  color: var(--font-color-primary);
}

.project-description-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 125px;
}

.project-header-image {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 12px;
}

.project-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.project-title {
  line-height: normal;
  font-size: 16px;
  font-weight: 700;
}

.project-description {
  line-height: 14px;
}

.project-description:first-child {
  font-size: 14px;
  font-weight: 400;
}

.project-description:last-child,
.read-more p {
  font-size: 12px;
  font-weight: 400;
}

.read-more {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
}

.read-more:hover {
  cursor: pointer;
}

.read-more p {
  margin-bottom: 0;
  color: #0017b4;
  text-decoration-line: underline;
}

.read-more .chevron-icon {
  font-size: 18px;
  color: #0017b4;
  transition: 0.25s all ease-in-out;
}

.project-header.expanded .read-more .chevron-icon {
  transform: rotate(-180deg);
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .project-description-wrapper {
    padding-top: 0;
  }
}
</style>
