<template>
  <div class="badges-main-wrapper">
    <button class="back" @click="$router.go(-1)">
      <img class="chevron" alt="chevron" :src="require('@/assets/icons/chevron_left.svg')" />
      <div>
        {{ t('back') }}
      </div>
    </button>

    <!-- How It Works -->
    <div
      v-if="account.accountType === 'business' || account.accountType === 'ecommerce'"
      class="how-it-works"
    >
      <HowItWorkShare />
    </div>

    <v-chip-group
      selected-class="active-filter"
      :model-value="activeFilter"
      @update:model-value="
        (v) => {
          if (!v) return
          openPanels = []
          activeFilter = v
        }
      "
      class="filter-group"
    >
      <v-chip value="all" class="filter-item" rounded="lg">{{ t('all') }}</v-chip>
      <v-chip value="badges" class="filter-item" rounded="lg">{{ t('badges') }}</v-chip>
      <v-chip value="banners" class="filter-item" rounded="lg">{{ t('banners') }}</v-chip>
      <v-chip value="widgets" class="filter-item" rounded="lg">{{ t('widgets') }}</v-chip>
    </v-chip-group>

    <div v-if="account.accountType === 'business' || account.accountType === 'ecommerce'">
      <div class="accordion-wrapper">
        <v-expansion-panels variant="accordion" flat multiple v-model="openPanels">
          <template v-if="activeFilter === 'all' || activeFilter === 'badges'">
            <v-expansion-panel class="v-expansion-panel-headers" value="badges">
              <v-expansion-panel-title class="widget-header">
                <img
                  :src="require('@/assets/icons/leaf-icon.svg')"
                  style="max-width: 25px; margin-right: 8px"
                />
                <div class="accordion-header-wrapper">
                  {{ t('static_header') }}
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="accordion-content">
                <div class="badge-container">
                  <Badge
                    v-for="(item, a) in staticWidgets"
                    :key="a"
                    class="badge"
                    :image="item.image"
                    :url="item.url"
                    :overlay-text="t('static')"
                    :colour="
                      item.type.toLowerCase().includes('light') ? 'background: #b1acac;' : ''
                    "
                    :account="account"
                    :is-shopify-integrated="isShopifyIntegrated"
                    :shopify-urls="shopifyUrls"
                    :from-shopify-marketplace="fromShopifyMarketplace"
                  />
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel class="v-expansion-panel-headers" value="round-badges">
              <v-expansion-panel-title class="widget-header">
                <img
                  :src="require('@/assets/icons/leaf-icon.svg')"
                  style="max-width: 25px; margin-right: 8px"
                />
                <div class="accordion-header-wrapper">
                  {{ t('round_header') }}
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="accordion-content">
                <div class="badge-container">
                  <Badge
                    v-for="(item, a) in roundWidgets"
                    :key="a"
                    class="badge"
                    :image="item.image"
                    :url="item.url"
                    :overlay-text="t('static')"
                    :is-shopify-integrated="isShopifyIntegrated"
                    :colour="
                      item.type.toLowerCase().includes('light') ? 'background: #b1acac;' : ''
                    "
                    :account="account"
                    :shopify-urls="shopifyUrls"
                  />
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </template>

          <template v-if="activeFilter === 'all' || activeFilter === 'widgets'">
            <v-expansion-panel value="dynamic-widgets" class="v-expansion-panel-headers">
              <v-expansion-panel-title class="widget-header">
                <img
                  :src="require('@/assets/icons/leaf-icon.svg')"
                  style="max-width: 25px; margin-right: 8px"
                />
                <div class="accordion-header-wrapper">
                  {{ t('dynamic_header') }}
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="accordion-content">
                <div class="badge-container">
                  <WidgetPreview
                    v-for="(widget, index) in topStatWidgets"
                    :key="index"
                    :api-key="widgetApiKey"
                  >
                    <template v-slot:widget>
                      <div v-html="widget.html" />
                    </template>
                  </WidgetPreview>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel
              v-for="(item, a) in impactSettingWidgets"
              :key="a"
              class="v-expansion-panel-headers"
              :value="`widget-${item.trigger}`"
            >
              <v-expansion-panel-title class="widget-header">
                <img
                  :src="require('@/assets/icons/leaf-icon.svg')"
                  style="max-width: 25px; margin-right: 8px"
                />
                <div class="accordion-header-wrapper">
                  {{ t(`${item.trigger}SectionTitle`) }}
                  <span class="header-pill">{{ t('shopify_two') }}</span>
                  <span class="header-pill">{{ t('widget_api') }}</span>
                  <span
                    class="header-pill"
                    v-if="isRebuyCompatible(item.trigger) && isRebuyEnabled"
                    >{{ t('rebuy_smart_cart') }}</span
                  >
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="accordion-content">
                <div>
                  <div class="badge-container tight-container">
                    <template v-if="item.trigger === 'offsetPerCurrentCart'">
                      <WidgetPreview
                        v-for="(widget, index) in cartWidgets"
                        :key="index"
                        :api-key="widgetApiKey"
                        trigger="offsetPerCurrentCart"
                        :color="widget.props?.color"
                        :style="widget.props?.style"
                      >
                        <template v-slot:widget>
                          <div v-html="widget.html" />
                        </template>
                      </WidgetPreview>
                    </template>

                    <template v-else-if="item.trigger === 'offsetPerOrder'">
                      <WidgetPreview
                        v-for="(widget, index) in perOrderWidgets"
                        :key="index"
                        :api-key="widgetApiKey"
                        trigger="offsetPerOrder"
                      >
                        <template v-slot:widget>
                          <div v-html="widget.html" />
                        </template>
                      </WidgetPreview>
                    </template>

                    <template v-else-if="item.trigger === 'offsetPerProduct'">
                      <WidgetPreview
                        v-for="(widget, index) in perProductWidgets"
                        :key="index"
                        :api-key="widgetApiKey"
                        trigger="offsetPerProduct"
                      >
                        <template v-slot:widget>
                          <div v-html="widget.html" />
                        </template>
                      </WidgetPreview>
                    </template>

                    <template v-else-if="item.trigger === 'offsetByPercentageOrder'">
                      <WidgetPreview
                        v-for="(widget, index) in perPercentageWidgets"
                        :key="index"
                        :api-key="widgetApiKey"
                        trigger="offsetByPercentageOrder"
                      >
                        <template v-slot:widget>
                          <div v-html="widget.html" />
                        </template>
                      </WidgetPreview>
                    </template>

                    <template v-else-if="item.trigger === 'offsetBySpend'">
                      <WidgetPreview
                        v-for="(widget, index) in perSpendLevelWidgets"
                        :key="index"
                        :api-key="widgetApiKey"
                        trigger="offsetBySpend"
                      >
                        <template v-slot:widget>
                          <div v-html="widget.html" />
                        </template>
                      </WidgetPreview>
                    </template>

                    <template v-else-if="item.trigger === 'offsetByStoreRevenue'">
                      <WidgetPreview
                        v-for="(widget, index) in perTieredSpendLevelWidgets"
                        :key="index"
                        :api-key="widgetApiKey"
                        trigger="offsetByStoreRevenue"
                      >
                        <template v-slot:widget>
                          <div v-html="widget.html" />
                        </template>
                      </WidgetPreview>
                    </template>

                    <template v-if="item.trigger === 'byPercentageOfRevenue'">
                      <WidgetRow :widget-type="item" :widget-api-key="widgetApiKey" />
                    </template>
                  </div>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </template>

          <v-expansion-panel
            class="v-expansion-panel-headers"
            v-if="activeFilter === 'all' || activeFilter === 'banners'"
            value="banners"
          >
            <v-expansion-panel-title class="widget-header">
              <img
                :src="require('@/assets/icons/leaf-icon.svg')"
                style="max-width: 25px; margin-right: 8px"
              />
              <div class="accordion-header-wrapper">
                {{ t('full_width_banner_header') }}
                <span class="header-pill">{{ t('shopify_two') }}</span>
                <span class="header-pill">{{ t('widget_api') }}</span>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="accordion-content">
              <div>
                <WidgetPreview
                  v-for="(widget, index) in fullWidthBannerWidgets"
                  :key="index"
                  :widget-api-key="widgetApiKey"
                  trigger="banner"
                >
                  <template v-slot:widget>
                    <div v-html="widget" style="width: 100%" />
                  </template>
                </WidgetPreview>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <LoadingOpaque v-if="loading" />
  </div>
</template>

<script lang="ts">
import GreensparkWidgets from '@getgreenspark/widgets'
import HowItWorkShare from '@/components/shareables/HowItWorkShare.vue'
import Badge from '@/components/shareables/Badge.vue'
import WidgetPreview from '@/components/shareables/WidgetPreview.vue'
import { isDemoEnvironment } from '@/helpers/constants'
import LoadingOpaque from '@/components/tools/LoadingOpaque.vue'
import { getApiKeys } from '@api/index'
import { defineComponent } from 'vue'
import WidgetRow from '@/components/shareables/WidgetRow.vue'

import type { ApiKeyResponse } from '@api/index'
import type { Notification } from '@/store/notification'
import type { BrandColor, IntegrationPlatform, StoreIntegrationTrigger } from '@/helpers/interfaces'
import { STORE_AUTOMATION_TRIGGERS } from '@/helpers/interfaces'
import type { Account } from '@/store'
import type { AutomationModifier, AutomationOffset, Integration } from '@/store/integrations'

export type StaticBadge = { image: string; url: string; type: string }

export type WidgetType = {
  trigger: StoreIntegrationTrigger | 'offsetPerCurrentCart'
  status: 'active' | 'inactive'
  offsets: AutomationOffset[]
  modifiers?: AutomationModifier[]
}

export type TotalImpacts = {
  monthsEarthPositive: number
  trees: number
  carbon: number
  plastic: number
}

export type WidgetDefinition = {
  html: string
  props?: {
    color?: BrandColor
    style?: 'default' | 'simplified'
  }
}

type PanelId =
  | 'badges'
  | 'round-badges'
  | 'dynamic-widgets'
  | 'widget-offsetPerCurrentCart'
  | 'widget-offsetPerOrder'
  | 'widget-offsetPerProduct'
  | 'widget-offsetAllProducts'
  | 'widget-offsetByPercentageOrder'
  | 'widget-offsetBySpend'
  | 'widget-offsetByStoreRevenue'
  | 'banners'
export default defineComponent({
  name: 'BadgesMain',
  components: {
    LoadingOpaque,
    HowItWorkShare,
    Badge,
    WidgetRow,
    WidgetPreview,
  },
  data() {
    return {
      impactSettingWidgets: [],
      roundWidgets: [],
      staticWidgets: [],
      fromShopifyMarketplace: false,
      loading: false,
      activeFilter: 'all',
      apiKeys: [],
      activePanels: [],
      widgetSDK: undefined,
      widgetSdkColors: ['beige', 'green', 'blue', 'white', 'black', 'grey', 'transparent'],
      topStatWidgets: [],
      cartWidgets: [],
      perOrderWidgets: [],
      perProductWidgets: [],
      perPercentageWidgets: [],
      perSpendLevelWidgets: [],
      perTieredSpendLevelWidgets: [],
      fullWidthBannerWidgets: [],
    } as {
      impactSettingWidgets: WidgetType[]
      roundWidgets: StaticBadge[]
      staticWidgets: StaticBadge[]
      fromShopifyMarketplace: boolean
      activeFilter: 'all' | 'badges' | 'banners' | 'widgets'
      activePanels: PanelId[]
      loading: boolean
      apiKeys: ApiKeyResponse[]
      widgetSDK: typeof GreensparkWidgets
      widgetSdkColors: BrandColor[]
      topStatWidgets: WidgetDefinition[]
      cartWidgets: WidgetDefinition[]
      perOrderWidgets: WidgetDefinition[]
      perProductWidgets: WidgetDefinition[]
      perPercentageWidgets: WidgetDefinition[]
      perSpendLevelWidgets: WidgetDefinition[]
      perTieredSpendLevelWidgets: WidgetDefinition[]
      fullWidthBannerWidgets: WidgetDefinition[]
    }
  },
  computed: {
    openPanels: {
      get(): PanelId[] {
        return this.activePanels
      },
      set(panels: PanelId[]) {
        if (panels.includes('dynamic-widgets')) this.loadTopStatWidgets()
        if (panels.includes('widget-offsetPerCurrentCart')) this.loadCartWidgets()
        if (panels.includes('widget-offsetPerOrder')) this.loadPerOrderWidgets()
        if (panels.includes('widget-offsetPerProduct')) this.loadPerProductWidgets()
        if (panels.includes('widget-offsetByPercentageOrder')) this.loadPerPercentageWidgets()
        if (panels.includes('widget-offsetBySpend')) this.loadPerSpendLevelWidgets()
        if (panels.includes('widget-offsetByStoreRevenue')) this.loadPerTieredSpendLevelWidgets()
        this.activePanels = panels
      },
    },
    isShopifyIntegrated(): boolean {
      return isDemoEnvironment
        ? true
        : !!this.getIntegrationsByPlatform('shopify').length || this.account.userFromMarketplace
    },
    shopifyUrls(): string[] {
      return isDemoEnvironment
        ? [this.integrationList[0].name]
        : this.getIntegrationsByPlatform('shopify').map(({ name }) => name)
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    integrationList(): Integration[] {
      return this.$store.getters['getIntegrationList']
    },
    setupChecklistHasWidgetCheck(): boolean {
      return this.$store.getters['getSetupChecklistItems'].some((i) => i.type === 'addWidgets')
    },
    getMonthsEarthPositive(): number {
      return this.$store.getters['getMonthsEarthPositive']
    },
    getIntegrationsByPlatform(): (platform: IntegrationPlatform) => Integration[] {
      return this.$store.getters['getIntegrationsByPlatform']
    },
    widgetApiKey(): ApiKeyResponse | undefined {
      return this.apiKeys.find((k) => k.subType === 'WIDGET_API_KEY')
    },
    isRebuyEnabled(): boolean {
      return this.$store.getters['getRebuyFeatureFlag']
    },
    language(): { locale: string } {
      return this.$store.getters['getUserLanguage']
    },
  },
  async created() {
    this.loading = true
    window.scrollTo(0, 0)

    await this.setAccount()
    await this.setIntegrations()
    this.setImpactSettingWidgets()
    this.setData()
    this.widgetSDK = new GreensparkWidgets({
      apiKey: '',
      integrationSlug: 'GS_PREVIEW',
      locale: this.language,
    })
    await this.fetchApiKeys()

    this.widgetSDK
      .fullWidthBanner({ version: 'v2', options: ['trees'] })
      .renderToString()
      .then((w) => (this.fullWidthBannerWidgets = [w]))
      .catch((e) => console.error('Failed to fetch full with banner with the widget SDK', e))

    this.loading = false
    if (this.setupChecklistHasWidgetCheck) await this.removeWidgetsFromSetupChecklist()
  },
  methods: {
    t(key: string) {
      return this.$t(`BadgesMain.${key}`)
    },
    isRebuyCompatible(type: StoreIntegrationTrigger | 'offsetPerCurrentCart'): boolean {
      if (!type) return false
      return ['offsetPerCurrentCart'].includes(type)
    },
    setData() {
      const randomNumber = Math.random().toFixed(2)

      // Beige
      this.roundWidgets.push({
        image: `https://get-mads.fra1.cdn.digitaloceanspaces.com/widget-round-beige.svg?version=${randomNumber.toString()}`,
        url: 'https://get-mads.fra1.cdn.digitaloceanspaces.com/widget-round-beige.svg',
        type: 'staticRoundBeige',
      })

      this.roundWidgets.push({
        image: `https://get-mads.fra1.cdn.digitaloceanspaces.com/widget-round-alt-beige.svg?version=${randomNumber.toString()}`,
        url: 'https://get-mads.fra1.cdn.digitaloceanspaces.com/widget-round-alt-beige.svg',
        type: 'staticRoundBeigeV2',
      })

      // Green
      this.roundWidgets.push({
        image: `https://get-mads.fra1.cdn.digitaloceanspaces.com/widget-round-green.svg?version=${randomNumber.toString()}`,
        url: 'https://get-mads.fra1.cdn.digitaloceanspaces.com/widget-round-green.svg',
        type: 'staticRoundGreen',
      })

      this.roundWidgets.push({
        image: `https://get-mads.fra1.cdn.digitaloceanspaces.com/widget-round-alt-green.svg?version=${randomNumber.toString()}`,
        url: 'https://get-mads.fra1.cdn.digitaloceanspaces.com/widget-round-alt-green.svg',
        type: 'staticRoundGreenV2',
      })

      // Blue
      this.roundWidgets.push({
        image: `https://get-mads.fra1.cdn.digitaloceanspaces.com/widget-round-blue.svg?version=${randomNumber.toString()}`,
        url: 'https://get-mads.fra1.cdn.digitaloceanspaces.com/widget-round-blue.svg',
        type: 'staticRoundBlue',
      })

      this.roundWidgets.push({
        image: `https://get-mads.fra1.cdn.digitaloceanspaces.com/widget-round-alt-blue.svg?version=${randomNumber.toString()}`,
        url: 'https://get-mads.fra1.cdn.digitaloceanspaces.com/widget-round-alt-blue.svg',
        type: 'staticRoundBlueV2',
      })

      // Dark
      this.roundWidgets.push({
        image: `https://get-mads.fra1.cdn.digitaloceanspaces.com/widget-round-dark.svg?version=${randomNumber.toString()}`,
        url: 'https://get-mads.fra1.cdn.digitaloceanspaces.com/widget-round-dark.svg',
        type: 'staticRoundDark',
      })

      // Light
      this.roundWidgets.push({
        image: `https://get-mads.fra1.cdn.digitaloceanspaces.com/widget-round-light.svg?version=${randomNumber.toString()}`,
        url: 'https://get-mads.fra1.cdn.digitaloceanspaces.com/widget-round-light.svg',
        type: 'staticRoundLight',
      })

      const availableColorsV2 = ['beige', 'green', 'blue', 'black', 'gray', 'transparent']
      availableColorsV2.forEach((color) => {
        const badgeUrl = `https://cdn.getgreenspark.com/widgets/static/static_badge_v2_${this.language}_${color}.svg`
        this.roundWidgets.push({
          image: `${badgeUrl}?version=${randomNumber.toString()}`,
          url: badgeUrl,
          type: `staticRound${color.charAt(0).toUpperCase() + color.slice(1)}`,
        })

        const rectangular2LinesUrl = `https://cdn.getgreenspark.com/widgets/static/static_widget_2_line_v2_${this.language}_${color}.svg`
        this.staticWidgets.push({
          image: `${rectangular2LinesUrl}?version=${randomNumber.toString()}`,
          url: rectangular2LinesUrl,
          type: `staticHorizontal2Lines${color.charAt(0).toUpperCase() + color.slice(1)}`,
        })

        const simplifiedUrl = `https://cdn.getgreenspark.com/widgets/static/static_widget_simplified_v2_${this.language}_${color}.svg`
        this.staticWidgets.push({
          image: `${simplifiedUrl}?version=${randomNumber.toString()}`,
          url: simplifiedUrl,
          type: `staticHorizontalSimplified${color.charAt(0).toUpperCase() + color.slice(1)}`,
        })
      })
    },
    setImpactSettingWidgets() {
      const temporaryImpactSettings: WidgetType[] = [
        {
          trigger: 'offsetPerCurrentCart',
          // set the cart status to active if any of the integrations have an active automation
          status: this.integrationList?.some(({ automationList }) => automationList.length)
            ? 'active'
            : 'inactive',
          offsets: [
            { type: 'trees', amount: 4 },
            { type: 'carbon', amount: 100 },
            { type: 'plastic', amount: 15 },
          ],
        },
      ]
      // create a widget for each trigger type, if an integration has an active automation for that trigger type, use that automation's offsets and status
      // else use the default offsets and status
      const triggerTypes = [
        ...STORE_AUTOMATION_TRIGGERS.filter((trigger) => trigger !== 'offsetAllProducts'),
      ] as (StoreIntegrationTrigger | 'offsetPerCurrentCart')[]
      triggerTypes.forEach((trigger) => {
        const integrationWithTrigger = this.integrationList?.find(({ automationList }) =>
          automationList.some(({ trigger: triggerType }) => triggerType === trigger),
        )
        const automationWithTrigger = integrationWithTrigger
          ? integrationWithTrigger?.automationList.find(
              ({ trigger: triggerType }) => triggerType === trigger,
            )
          : undefined
        if (automationWithTrigger) {
          const widgetSetting: WidgetType = {
            trigger,
            offsets: automationWithTrigger.offsets,
            status: automationWithTrigger.status === 'active' ? 'active' : 'inactive',
          }
          if (automationWithTrigger.modifiers) {
            widgetSetting.modifiers = []
            widgetSetting.modifiers = automationWithTrigger.modifiers
          }
          temporaryImpactSettings.push(widgetSetting)
        } else {
          temporaryImpactSettings.push({
            trigger,
            offsets: [
              { type: 'trees', amount: 4 },
              { type: 'carbon', amount: 100 },
              { type: 'plastic', amount: 15 },
            ],
            status: 'inactive',
          })
        }
      })
      this.impactSettingWidgets = temporaryImpactSettings
    },
    async batchFetchWidgets(requests: Promise<WidgetDefinition>[]): Promise<WidgetDefinition[]> {
      try {
        const results = await Promise.allSettled<WidgetDefinition>(requests)

        const errors = results
          .filter(({ status }) => status === 'rejected')
          .map((r) => ('reason' in r ? r.reason : ''))
        if (errors.length > 0) console.error('Errors loading widgets', errors)

        return results
          .filter(({ status }) => status === 'fulfilled')
          .map((r) => ('value' in r ? r.value : { html: '' }))
      } catch (error) {
        console.error('Error loading widgets', error)
        return []
      }
    },
    async loadTopStatWidgets(): Promise<void> {
      if (this.topStatWidgets.length > 0) return
      this.topStatWidgets = await this.batchFetchWidgets(
        this.widgetSdkColors.map(async (color) => ({
          html: await this.widgetSDK
            .topStats({ version: 'v2', withPopup: true })
            .renderToString({ color }),
          props: { color },
        })),
      )
    },
    async loadCartWidgets(): Promise<void> {
      if (this.cartWidgets.length > 0) return
      const currency = this.account.currency
      const widget = this.widgetSDK.cart({
        version: 'v2',
        withPopup: false,
        order: { currency, totalPrice: 0, lineItems: [] },
      })
      this.cartWidgets = await this.batchFetchWidgets([
        ...this.widgetSdkColors.map(async (color) => ({
          html: await widget.renderToString({ color }),
          props: { color },
        })),
        ...this.widgetSdkColors.map(async (color) => ({
          html: await widget.renderToString({ color, style: 'simplified' }),
          props: { color, style: 'simplified' },
        })),
      ])
    },
    async loadPerOrderWidgets(): Promise<void> {
      if (this.perOrderWidgets.length > 0) return
      const currency = this.account.currency
      const widget = this.widgetSDK.perOrder({
        version: 'v2',
        withPopup: false,
        currency,
      })
      this.perOrderWidgets = await this.batchFetchWidgets([
        ...this.widgetSdkColors.map(async (color) => ({
          html: await widget.renderToString({ color }),
          props: { color },
        })),
        ...this.widgetSdkColors.map(async (color) => ({
          html: await widget.renderToString({ color, style: 'simplified' }),
          props: { color, style: 'simplified' },
        })),
      ])
    },
    async loadPerProductWidgets(): Promise<void> {
      if (this.perProductWidgets.length > 0) return
      const widget = this.widgetSDK.perProduct({
        version: 'v2',
        withPopup: false,
        productId: '0000',
        style: 'default',
      })
      this.perProductWidgets = await this.batchFetchWidgets([
        ...this.widgetSdkColors.map(async (color) => ({
          html: await widget.renderToString({ color }),
          props: { color },
        })),
        ...this.widgetSdkColors.map(async (color) => ({
          html: await widget.renderToString({ color, style: 'simplified' }),
          props: { color, style: 'simplified' },
        })),
      ])
    },
    async loadPerPercentageWidgets(): Promise<void> {
      if (this.perPercentageWidgets.length > 0) return
      const widget = this.widgetSDK.byPercentage({
        version: 'v2',
        withPopup: false,
        style: 'default',
      })

      this.perPercentageWidgets = await this.batchFetchWidgets([
        ...this.widgetSdkColors.map(async (color) => ({
          html: await widget.renderToString({ color }),
          props: { color },
        })),
        ...this.widgetSdkColors.map(async (color) => ({
          html: await widget.renderToString({ color, style: 'simplified' }),
          props: { color, style: 'simplified' },
        })),
      ])
    },
    async loadPerSpendLevelWidgets(): Promise<void> {
      if (this.perSpendLevelWidgets.length > 0) return
      const currency = this.account.currency
      const widget = this.widgetSDK.spendLevel({
        version: 'v2',
        withPopup: false,
        currency,
        style: 'default',
      })

      this.perSpendLevelWidgets = await this.batchFetchWidgets([
        ...this.widgetSdkColors.map(async (color) => ({
          html: await widget.renderToString({ color }),
          props: { color },
        })),
        ...this.widgetSdkColors.map(async (color) => ({
          html: await widget.renderToString({ color, style: 'simplified' }),
          props: { color, style: 'simplified' },
        })),
      ])
    },
    async loadPerTieredSpendLevelWidgets(): Promise<void> {
      if (this.perTieredSpendLevelWidgets.length > 0) return
      const currency = this.account.currency
      const widget = this.widgetSDK.tieredSpendLevel({
        version: 'v2',
        withPopup: false,
        currency,
        style: 'default',
      })
      this.perTieredSpendLevelWidgets = await this.batchFetchWidgets([
        ...this.widgetSdkColors.map(async (color) => ({
          html: await widget.renderToString({ color }),
          props: { color },
        })),
        ...this.widgetSdkColors.map(async (color) => ({
          html: await widget.renderToString({ color, style: 'simplified' }),
          props: { color, style: 'simplified' },
        })),
      ])
    },
    setAccount(): Promise<void> {
      return this.$store.dispatch('setAccount')
    },
    setIntegrations(): Promise<void> {
      return this.$store.dispatch('setIntegrations')
    },
    removeWidgetsFromSetupChecklist(): Promise<void> {
      return this.$store.dispatch('updateChecklist', 'addWidgets')
    },
    async fetchApiKeys() {
      try {
        const { data } = await getApiKeys()
        this.apiKeys = data
      } catch (error) {
        this.$store.dispatch('notification/notify', {
          text: 'An unexpected error occurred while trying to retrieve your API keys. Please try again or get in touch.',
          isError: true,
        } as Notification)
        console.error(error)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.badges-main-wrapper {
  animation-delay: 0.25s;
  animation: fadeInAnimation ease 0.99s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.back {
  display: flex;
  font-weight: 500;
  color: #3b755f;
  cursor: pointer;
  margin-bottom: 7px;
  width: 70px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 3px;
}

.chevron {
  width: 23px;
  filter: invert(39%) sepia(15%) saturate(1175%) hue-rotate(106deg) brightness(96%) contrast(87%);
}

.how-it-works {
  margin-bottom: 42px;
}

.accordion-wrapper {
  margin: 8px 0px;
}

.v-expansion-panel {
  border-radius: 8px;
}

.v-expansion-panel-headers {
  margin: 8px 0;
  background: #f9f9f9;
  box-shadow: 1px -1px 10px -1px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 1px -1px 10px -1px rgb(0 0 0 / 10%);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
}

.filter-group {
  .filter-item {
    padding: 8px 16px;
    height: auto;
    margin-right: 24px;

    &:deep(.v-chip__content) {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .active-filter {
    background-color: var(--ui-green);
    color: white;
  }
}

.accordion-content {
  padding-bottom: 10px;
  background: #f9f9f9;
  border-radius: 4px;
}

.badge-wrapper {
  margin-top: 25px;
  background-color: #f9f9f9 !important;
  box-shadow: 0 42px 76px rgba(0, 0, 0, 0.05), 0 27.2222px 44.5093px rgba(0, 0, 0, 0.037963),
    0 16.1778px 24.2074px rgba(0, 0, 0, 0.0303704), 0 8.4px 12.35px rgba(0, 0, 0, 0.025),
    0 3.42222px 6.19259px rgba(0, 0, 0, 0.0196296), 0 0.777778px 2.99074px rgba(0, 0, 0, 0.012037);
  border-radius: 8px;
  padding: 36px 0;
}

.badge-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  padding: 0;

  .tight-container {
    padding: 0px 24px;

    @media #{map-get($display-breakpoints, 'xs')} {
      padding: 0;
    }
  }
}

.badge-container::after {
  content: '';
  flex: inherit;
  width: 31%;
}

.widget-header {
  color: #212121;
  font-size: 20px;
  font-weight: 600;
  background: #f9f9f9;
  padding: 16px 24px;
}

.accordion-header-wrapper {
  display: flex;
  align-items: center;
}

.header-pill {
  display: flex;
  padding: 5px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #f2ebdb;
  color: #3b755f;
  border-radius: 8px;
  font-size: 14px;
}

.line-wrapper {
  margin-left: 24px;
  margin-right: 24px;
}

.divider-line {
  height: 1px;
  width: 100%;
  background-color: black;
  opacity: 0.2;
  margin-top: 14px;
}

.badge {
  flex: 0 0 auto;
  margin: 6px;
  width: 31%;
  transition: all 0.3s ease;
  box-shadow: none;
}

.badge:hover {
  -webkit-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  transform: translateY(-3px);
}

@media screen and (max-width: 975px) {
  .badge {
    width: 100%;
  }
}
</style>
