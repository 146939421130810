import type { ActionContext } from 'vuex'
import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import type { FeatureSettings } from './feature-settings'
import { featureSettingsModule } from './feature-settings'
import type { SubscriptionsState } from '@/store/subscriptions'
import { subscriptionsModule } from '@/store/subscriptions'
import { User, usersModule } from '@/store/users'
import { dialogModule } from '@/store/dialog'

import type {
  Clearable,
  Currency,
  CurrencyCode,
  Customer,
  OffsetType,
  TopStatDataKey,
  TotalImpact,
} from '@/helpers/interfaces'
import { OFFSET_TYPES, SUBSCRIPTION_OFFSET_TYPES } from '@/helpers/interfaces'
import type { AlertType } from '@/helpers/constants'
import {
  isDemoEnvironment,
  isDeployPreviewEnvironment,
  isDevelopmentEnvironment,
  isLocalEnvironment,
  isProductionEnvironment,
} from '@/helpers/constants'
import { getCurrencyCode, getCurrencySymbol } from '@/helpers/pricing'
import { featureFlagsModule } from '@/store/feature-flags'
import { customerEngagementModule } from '@/store/customer-engagement'
import { addReferral, fetchAccount, getActionsRequired, getCustomer, getUser } from '@api/index'
import { onboardingModule } from '@/store/onboarding'
import { checklistModule } from '@/store/checklist'
import type { Notification } from '@/store/notification'
import VueI18n from '@/i18n'
import type { AutomationOffset } from '@/store/integrations'
import { integrationsModule } from '@/store/integrations'
import { bankAccountsModule } from '@/store/bank-accounts'
import { projectsModule } from '@/store/projects'
import { apiUsageModule } from '@/store/api-usage'
import type { TranslateResult } from 'vue-i18n'
import { Decimal } from 'decimal.js'
import type { AchievementData } from '@/components/your-impact/Achievements.vue'
import { cartModule } from '@/store/cart'
import { notificationModule } from '@/store/notification'
import { formQuestionModule } from '@/store/form-question'
import { parseImpactsArrayToObject } from '@/helpers/mixins/utils'

export const getLocalStorageKey = () => {
  if (isLocalEnvironment) return 'greenspark-local'
  if (isDevelopmentEnvironment) return 'greenspark-dev'
  if (isDemoEnvironment) return 'greenspark-demo'
  if (isDeployPreviewEnvironment) return 'greenspark-preview'
  if (isProductionEnvironment) return 'greenspark-prod'
  return 'vuex'
}

export type SetProductFetchStateByIntegrationIdParams = {
  state: ProductsFetchStatusType
  integrationId: string
  lastProductsFetchDate: string | null
}

export interface MerchantProduct {
  productId: string
  name: string
  type: string
  image: string
  active: boolean
  offsets: AutomationOffset[]
}

export type ProductsFetchStatusType = 'ready' | 'fetching' | 'notReady' | 'refetching'

export interface LanguageSetting {
  locale: string
  language: string
}

export const ACCOUNT_IMPACT_SOURCES = ['subscription', 'oneOff', 'account'] as const
export type AccountImpactSource = (typeof ACCOUNT_IMPACT_SOURCES)[number]
export const ACCOUNT_IMPACT_STATUSES = ['temporary', 'stashed', 'total'] as const
export type AccountImpactStatus = (typeof ACCOUNT_IMPACT_STATUSES)[number]

export interface AccountImpact {
  type: OffsetType
  amount: number
  projectId: string
  source: AccountImpactSource
  status: AccountImpactStatus
}

export interface RelationImpact {
  type: OffsetType
  amount: number
  projectId: string
  source: 'relation'
}

export interface TransferredImpact {
  type: OffsetType
  amount: number
  urls: string[]
}

export interface Account {
  accountId: string
  accountType: string
  companyName: string
  website: string
  vatId: string
  profileDescription: string
  imageURL?: string
  signupId: string
  signupComplete: boolean
  emailAlreadyUsed: boolean
  birthday: {
    year: number | null
    month: number | null
    day: number | null
  }
  phoneNumber: string
  marketingAccepted: boolean
  termsAndConditions: boolean
  address: {
    street: string
    postCode: string
    city: string
    country: string
  }
  transferredImpacts: TransferredImpact[]
  personalPlans: {
    personal: null
    plasticHero: null
    earthHero: number | null
    positiveFamilyAdult: null
    positiveFamilyChild: null
    positiveFamilyPet: null
    earthPositiveFamilyAdult: null
    earthPositiveFamilyChild: null
    earthPositiveFamilyPet: null
  }
  personalPlanIds: {
    personal: string
    plasticHero: string
    earthHero: string
    positiveFamilyAdult: string
    positiveFamilyChild: string
    positiveFamilyPet: string
    earthPositiveFamilyAdult: string
    earthPositiveFamilyChild: string
    earthPositiveFamilyPet: string
    treeAddon: string
    plasticAddon: string
  }
  employeePlans: {
    standard: number | null
    superior: number | null
    premium: number | null
  }
  employeePlanIds: {
    standard: string
    superior: string
    premium: string
    treePlanter: string
    plasticHero: string
  }
  employeePlanAddons: {
    treePlanter: number | null
    plasticHero: number | null
  }
  personalPlanAddons: {
    treeAddon: number | null
    plasticAddon: number | null
  }
  temporaryUpgrades: {
    // temporary saving of upgrades / downgrades. Gets reset once next invoice is paid.
    standard: number | null
    superior: number | null
    premium: number | null
    treePlanter: number | null
    plasticHero: number | null
  }
  stashedUpgrades: {
    // temporary saving of upgrades / downgrades. Gets reset once next invoice is paid.
    standard: number | null
    superior: number | null
    premium: number | null
    treePlanter: number | null
    plasticHero: number | null
  }
  oneOfftransactions: null
  plantransactions: null
  widgetFolderName: string
  widgets: {
    type: string
    url: string
  }[]
  livePageId: string
  signupDate: string
  currency: Currency
  currencyPreChange: Currency
  relationImpact?: TotalImpact[]
  impactActionImpact?: TotalImpact[]
  userFromMarketplace: boolean
  discounts: {
    type: string
    amount: number | null
  }[]
  sustainabilitySite: string
  createdAt: Clearable<string>
  impacts: AccountImpact[]
}

export interface AccountResponse extends Account {}

export interface AuthUser {
  uid: string
}

export interface StripeURLs {
  successURLBusiness: string
  successURLEcommerce: string
  successURLPersonal: string
  cancelURLPersonal: string
  successURLFamily: string
  cancelURLFamily: string
}

interface StateConstants {
  impactTypes: ImpactTypeConstant[]
}

export interface StateInterface {
  login: boolean
  loginError: boolean
  passwordReset: boolean
  passwordResetError: boolean
  account: Account
  languageSettings: LanguageSetting
  authUser: AuthUser
  currentUser: User
  stripeURLs: StripeURLs
  loading: boolean // used to add overlay to nav bar for loading
  burgerMenuOpen: boolean // used to close burgermenu programatically
  publicToken: string // used if a user connects via shopify
  alertTypes: AlertType[]
  showTopAlert: boolean
  fundingEndsDate: string
  relationAllowanceAmount: number
  relationId: string
  customer: Customer
  featureSettings: FeatureSettings
  subscriptionItems: SubscriptionsState
  constants: Readonly<StateConstants>
  analyticsDateRange: Date[]
  isEmailSyncAlertDismissed: boolean
}
export type ImpactIconId =
  | '#trees-icon'
  | '#carbon-icon'
  | '#plastic-icon'
  | '#kelp-icon'
  | '#water-icon'
export interface ImpactTypeConstant {
  type: OffsetType
  iconId: ImpactIconId
  label: TranslateResult
  teamMemberMultiplier: TeamMemberMultiplier
  referralAmount: number
}
export interface TeamMemberMultiplier {
  standard: number
  premium: number
  superior: number
  addon: number
}

export type TopStatData = {
  [key in TopStatDataKey]: number
}

const impactTypes: ImpactTypeConstant[] = [
  {
    type: 'trees',
    iconId: '#trees-icon',
    label: VueI18n.global.t('trees.label'),
    teamMemberMultiplier: { standard: 1, premium: 1, superior: 1, addon: 1 },
    referralAmount: 30,
  },
  {
    type: 'carbon',
    iconId: '#carbon-icon',
    label: VueI18n.global.t('carbon.label'),
    teamMemberMultiplier: { standard: 1.42, premium: 5.7, superior: 2.84, addon: 1 },
    referralAmount: 1000,
  },
  {
    type: 'plastic',
    iconId: '#plastic-icon',
    label: VueI18n.global.t('plastic.label'),
    teamMemberMultiplier: { standard: 100, premium: 100, superior: 100, addon: 250 },
    referralAmount: 200,
  },
  {
    type: 'kelp',
    iconId: '#kelp-icon',
    label: VueI18n.global.t('kelp.label'),
    teamMemberMultiplier: { standard: 0, premium: 0, superior: 0, addon: 0 },
    referralAmount: 0,
  },
  {
    type: 'water',
    iconId: '#water-icon',
    label: VueI18n.global.t('kelp.label'),
    teamMemberMultiplier: { standard: 0, premium: 0, superior: 0, addon: 0 },
    referralAmount: 0,
  },
]

const constants: Readonly<StateConstants> = Object.freeze({
  impactTypes: impactTypes,
} as StateConstants)

export default createStore({
  state: {
    languageSettings: {
      locale: 'en-GB',
      language: 'en',
    },
    passwordReset: false,
    passwordResetError: false,
    loginError: false,
    loading: false,
    login: false,
    burgerMenuOpen: false,
    publicToken: '',
    alertTypes: [],
    showTopAlert: false,
    relationId: '',
    fundingEndsDate: '',
    relationAllowanceAmount: 0,
    account: {
      accountId: '',
      accountType: '',
      companyName: '',
      website: '',
      vatId: '',
      profileDescription: '',
      imageURL: '',
      integrations: [],
      discounts: [],
      signupId: '',
      signupComplete: false,
      emailAlreadyUsed: false,
      birthday: {
        year: null,
        month: null,
        day: null,
      },
      phoneNumber: '',
      marketingAccepted: false,
      termsAndConditions: false,
      address: {
        street: '',
        postCode: '',
        city: '',
        country: '',
      },
      transferredImpacts: [],
      personalPlans: {
        personal: null,
        plasticHero: null,
        earthHero: null,
        positiveFamilyAdult: null,
        positiveFamilyChild: null,
        positiveFamilyPet: null,
        earthPositiveFamilyAdult: null,
        earthPositiveFamilyChild: null,
        earthPositiveFamilyPet: null,
      },
      personalPlanIds: {
        personal: '',
        plasticHero: '',
        earthHero: '',
        positiveFamilyAdult: '',
        positiveFamilyChild: '',
        positiveFamilyPet: '',
        earthPositiveFamilyAdult: '',
        earthPositiveFamilyChild: '',
        earthPositiveFamilyPet: '',
        treeAddon: '',
        plasticAddon: '',
      },
      employeePlans: {
        standard: null,
        superior: null,
        premium: null,
      },
      employeePlanIds: {
        standard: '',
        superior: '',
        premium: '',
        treePlanter: '',
        plasticHero: '',
      },
      employeePlanAddons: {
        treePlanter: null,
        plasticHero: null,
      },
      personalPlanAddons: {
        treeAddon: null,
        plasticAddon: null,
      },
      temporaryUpgrades: {
        standard: 0,
        superior: 0,
        premium: 0,
        treePlanter: 0,
        plasticHero: 0,
      },
      stashedUpgrades: {
        standard: 0,
        superior: 0,
        premium: 0,
        treePlanter: 0,
        plasticHero: 0,
      },
      oneOfftransactions: null,
      plantransactions: null,
      widgets: [],
      impacts: [],
      widgetFolderName: '',
      livePageId: '',
      signupDate: '',
      currency: 'dollar',
      currencyPreChange: 'dollar',
      relationImpact: [],
      impactActionImpact: [],
      userFromMarketplace: false,
      userPlanType: '',
      sustainabilitySite: '',
      createdAt: null,
    },
    authUser: {
      uid: '',
    },
    currentUser: {
      id: '',
      firstName: '',
      lastName: '',
      fullName: '',
      email: '',
      status: 'pending',
      role: 'owner',
      createdAt: new Date(0),
    },
    stripeURLs: {
      successURLBusiness: `${window.location.origin}/success?session_id={CHECKOUT_SESSION_ID}`,
      successURLEcommerce: `${window.location.origin}/success?session_id={CHECKOUT_SESSION_ID}`,
      successURLPersonal: `${window.location.origin}/success?session_id={CHECKOUT_SESSION_ID}`,
      cancelURLPersonal: `${window.location.origin}`,
      successURLFamily: `${window.location.origin}/success?session_id={CHECKOUT_SESSION_ID}`,
      cancelURLFamily: `${window.location.origin}`,
    },
    customer: {
      orderId: '',
      email: '',
      impacts: [],
      lastName: '',
      firstName: '',
      impactActionType: '',
      sellerCompanyName: '',
    },
    featureSettings: featureSettingsModule.state(),
    subscriptionItems: subscriptionsModule.state(),
    constants: constants,
    analyticsDateRange: [],
    isEmailSyncAlertDismissed: false,
  } as StateInterface,
  mutations: {
    setLoginError(s: StateInterface, value) {
      s.loginError = value
    },
    setPasswordReset(s: StateInterface, value) {
      s.passwordReset = value
    },
    setPasswordResetError(s: StateInterface, value) {
      s.passwordResetError = value
    },
    setAuthUser(s: StateInterface, user) {
      s.authUser = user
    },
    setCurrentUser(s: StateInterface, user) {
      s.currentUser = user
    },
    setLoading(s: StateInterface, value) {
      s.loading = value
    },
    setBurgerMenu(s: StateInterface, value) {
      s.burgerMenuOpen = value
    },
    setPublicToken(s: StateInterface, value) {
      s.publicToken = value
    },
    setAccount(s: StateInterface, account: Partial<Account>) {
      Object.keys(s.account).forEach((key) => {
        if (account[key] != null) {
          s.account[key] = account[key]
        }
      })
    },
    setUserId(s: StateInterface, userId: string) {
      s.currentUser.id = userId
    },
    setUserEmployeePlans(s, value: object) {
      Object.keys(s.account.employeePlans).forEach((key) => {
        if (value[key]) {
          s.account.employeePlans[key] = value[key]
        }
      })
    },
    setUserPersonalPlans(s: StateInterface, value: object) {
      Object.keys(s.account.personalPlans).forEach((key) => {
        if (value[key] !== undefined) {
          s.account.personalPlans[key] = value[key]
        }
      })
    },
    setUserEmployeePlanAddons(s: StateInterface, value: object) {
      Object.keys(s.account.employeePlanAddons).forEach((key) => {
        if (value[key]) {
          s.account.employeePlanAddons[key] = value[key]
        }
      })
    },
    setUserPersonalPlanAddons(s: StateInterface, value: object) {
      Object.keys(s.account.personalPlanAddons).forEach((key) => {
        if (value[key]) {
          s.account.personalPlanAddons[key] = value[key]
        }
      })
    },
    setSignupId(s: StateInterface, value: string) {
      s.account.signupId = value
    },
    setEmailAlreadyUsed(s: StateInterface, value: boolean) {
      s.account.emailAlreadyUsed = value
    },
    setSignupComplete(s: StateInterface, value: boolean) {
      s.account.signupComplete = value
    },
    setRequiredActionAlert(s: StateInterface, alertTypes: AlertType[]) {
      s.alertTypes = alertTypes
    },
    setRelationId(s: StateInterface, relationId: string) {
      s.relationId = relationId
    },
    setFundingEndsDate(s: StateInterface, fundingEndsDate: string) {
      s.fundingEndsDate = fundingEndsDate
    },
    setTopAlert(s: StateInterface, value: boolean) {
      s.showTopAlert = value
    },
    setRelationImpact(s: StateInterface, relationImpact: TotalImpact[]) {
      s.account.relationImpact = relationImpact
    },
    setRelationAllowanceAmount(s: StateInterface, relationAllowanceAmount: number) {
      s.relationAllowanceAmount = relationAllowanceAmount
    },
    setImpactActionImpact(s: StateInterface, impactActionImpact: TotalImpact[]) {
      s.account.impactActionImpact = impactActionImpact
    },
    setCustomerData(s: StateInterface, customer: Customer) {
      s.customer = customer
    },
    setLocale(s: StateInterface, locale: string) {
      s.languageSettings.locale = locale
    },
    setLanguage(s: StateInterface, language: string) {
      s.languageSettings.language = language
    },
    setAnalyticsDateRange(s: StateInterface, value: Date[]) {
      s.analyticsDateRange = value
    },
    setEmailSyncAlertDismissed(s: StateInterface, isDismissed: boolean) {
      s.isEmailSyncAlertDismissed = isDismissed
    },
  },
  actions: {
    async setupAccount({ dispatch }) {
      await dispatch('setAccount')
      await dispatch('setIntegrations')
      await dispatch('setImpactActionsState')

      // fetch user subscription-items, in case user has no ongoing subscription-item redirect to /finish-subscription page
      const redirectFinishSubscriptionRoute = await dispatch('setSubscriptionItems')
      if (redirectFinishSubscriptionRoute) return

      // fetch user onboarding steps, in case user has not completed or skipped onboarding redirect to /onboarding page
      const redirectOnboardingRoute = await dispatch('setOnboardingSkipped')
      if (redirectOnboardingRoute) return
    },
    async setCustomerData(
      { commit }: ActionContext<StateInterface, StateInterface>,
      customerId: string,
    ) {
      commit('setLoading', true)
      try {
        const { data } = await getCustomer(customerId)
        commit('setCustomerData', data)
      } catch (error) {
        if (error.response?.status === 404) {
          window.location.replace('https://www.getgreenspark.com/404')
        }
      } finally {
        commit('setLoading', false)
      }
    },
    setUserOrders: (context: ActionContext<StateInterface, StateInterface>) => {
      context.commit('setUserOrders')
    },
    setUserImpactsByBrand: (context: ActionContext<StateInterface, StateInterface>) => {
      context.commit('setUserImpactsByBrand')
    },
    setSustainabilitySite: (context: ActionContext<StateInterface, StateInterface>) => {
      context.commit('setSustainabilitySite')
    },
    async setAccount(
      { commit, dispatch }: ActionContext<StateInterface, StateInterface>,
      setSubscriptionItems = true,
      setIntegrations = false,
    ) {
      try {
        const { data } = await fetchAccount()
        commit('setAccount', data)
        if (setSubscriptionItems) {
          await dispatch('setSubscriptionItems')
        }
        if (setIntegrations) {
          await dispatch('setIntegrations')
        }
        await Promise.all([
          await dispatch('setCurrentUser'),
          await dispatch('identifyLdcContext'),
          await dispatch('setProjectList'),
        ])
      } catch (error) {
        console.error(error)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
    },
    async setRequiredActionAlert({
      commit,
      getters,
      dispatch,
    }: ActionContext<StateInterface, StateInterface>) {
      try {
        const { data } = await getActionsRequired()
        if (data.length) {
          commit(
            'setRequiredActionAlert',
            data.map(({ type }) => type),
          )
          commit('setRelationId', data.find(({ relationId }) => relationId)?.relationId)
          commit('setRelationAllowanceAmount', data.find(({ amount }) => amount)?.amount)
          commit(
            'setFundingEndsDate',
            data.find(({ fundingEndsDate }) => fundingEndsDate)?.fundingEndsDate,
          )
          commit('setTopAlert', true)
        } else if (getters.getActiveSubscriptionItem?.product === 'freeBusiness') {
          commit('setRequiredActionAlert', ['freeBusinessSubscription'])
          commit('setTopAlert', true)
        } else {
          commit('setRequiredActionAlert', [])
          commit('setTopAlert', false)
        }
      } catch (error) {
        console.error(error)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
    },
    async addReferral({ dispatch }: ActionContext<StateInterface, StateInterface>) {
      try {
        const referralInviteId = decodeURIComponent(document.cookie)
          .split('; ')
          .find((row) => row.startsWith('refGS='))
          ?.split('=')[1]

        // if the cookie is present then update the users accordingly & remove the cookie
        if (referralInviteId) {
          document.cookie = 'refGS=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
          const payload = {
            referralInviteId,
          }
          await addReferral(payload)
          await dispatch('setAccount')
        }
      } catch (error) {
        console.error(error)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
    },
    async setCurrentUser({ commit, rootState }: ActionContext<StateInterface, StateInterface>) {
      const { data } = await getUser(rootState.authUser.uid)
      commit('setCurrentUser', new User(data))
      if (window.beamer_config) {
        window.Beamer.update({ user_id: data.userId })
      }
    },
    updateCurrentUser(
      { commit, state }: ActionContext<StateInterface, StateInterface>,
      userProps: Partial<User>,
    ) {
      const user = { ...state.currentUser, ...userProps }
      commit('setCurrentUser', user)
    },
    async setLanguageSettings(
      { commit, state }: ActionContext<StateInterface, StateInterface>,
      locale: string,
    ) {
      // receives a locale string and sets the language and locale in the store
      commit('setLocale', locale)
      // for any locale that is not german, set the language to english
      if (/^de\b/.test(locale)) {
        commit('setLanguage', 'de')
      } else {
        commit('setLanguage', 'en')
      }
      if (state.languageSettings.language === 'en' || state.languageSettings.language === 'de') {
        VueI18n.global.locale.value = state.languageSettings.language
      }
    },
  },
  getters: {
    getImpactTypes: () => impactTypes,
    getPublicToken: (s) => s.publicToken,
    getAccountImpacts: (s): TotalImpact[] => {
      const impactActionImpact = ['business', 'ecommerce'].includes(s.account?.accountType)
        ? []
        : s.account?.impactActionImpact || []
      const totalImpacts = [
        ...(s.account?.impacts || []),
        ...(s.account?.relationImpact || []),
        ...(s.account?.transferredImpacts || []),
        ...impactActionImpact,
      ]
      return OFFSET_TYPES.map((type) => {
        const amount =
          totalImpacts
            .filter((impact) => impact.type === type)
            .reduce((acc, impact) => new Decimal(acc).add(impact.amount).toNumber(), 0) || 0
        return { type, amount }
      })
    },
    getImpactsByProject:
      (s) =>
      (projectId: string): AccountImpact | undefined => {
        return s.account.impacts.find((i) => i.projectId === projectId)
      },
    getTotalImpactsByType: (s: StateInterface, getters): { type: OffsetType; amount: number }[] => {
      return constants.impactTypes.map(({ type }) => {
        return {
          type,
          amount: getters.getTotalImpactByType(type),
        }
      })
    },
    getTotalImpactByType:
      (s: StateInterface) =>
      (type: OffsetType): number => {
        const impactActionImpact = ['business', 'ecommerce'].includes(s.account?.accountType)
          ? []
          : s.account?.impactActionImpact || []
        const totalImpacts = [
          ...(s.account?.impacts || []),
          ...(s.account?.relationImpact || []),
          ...(s.account?.transferredImpacts || []),
          ...impactActionImpact,
        ]
        return (
          totalImpacts
            .filter((impact) => impact.type === type)
            .reduce((acc, impact) => new Decimal(acc).add(impact.amount).toNumber(), 0) || 0
        )
      },
    getMonthsEarthPositive: (s) => {
      const today = new Date()
      const difference = Math.floor(today.getTime() - Date.parse(s.account.signupDate))
      const day = 1000 * 60 * 60 * 24
      const days = Math.floor(difference / day)
      return Math.round(days / 28)
    },
    getCustomerData: (s) => {
      return s.customer
    },
    getUserCurrencySymbol: (s, getters) => {
      return getCurrencySymbol(getters.getUserCurrencyCode)
    },
    getUserCurrency: (s): Currency => {
      return s.account.currency
    },
    getUserCurrencyCode: (s): CurrencyCode => {
      return getCurrencyCode(s.account.currency)
    },
    getStoreAccessTokenByStoreType:
      (sate, getters) =>
      (rutterStoreType: string): string => {
        return getters.getUserStores.find(({ platform }) => platform === rutterStoreType).id
      },
    getLivePageUrl: (s): string => {
      if (s.account.accountType === 'business' || s.account.accountType === 'ecommerce') {
        const companyNameUrl = s.account?.companyName
          ? encodeURIComponent(s.account?.companyName.replace(/\s/g, '-').toLowerCase())
          : ''
        return `/dashboard/${s.account.livePageId}/${companyNameUrl}`
      } else {
        const nameUrl =
          s.currentUser.firstName.replace(/\s/g, '-').toLowerCase() +
          '-' +
          s.currentUser.lastName.replace(/\s/g, '-').toLowerCase()
        return `/dashboard/${s.account.livePageId}/${nameUrl}`
      }
    },
    getAccountTypeIsBusiness: (s): boolean => {
      return s.account.accountType === 'business' || s.account.accountType === 'ecommerce'
    },
    getAccountDisplayName: (state, getters): string => {
      return getters.getAccountTypeIsBusiness
        ? state.account.companyName
        : state.currentUser.firstName
    },
    getAccountIsAdmin: (s): boolean => {
      return s.account.accountType === 'admin'
    },
    getCurrentUserFullName: (s): string => {
      return s.currentUser.firstName && s.currentUser.lastName
        ? `${s.currentUser.firstName} ${s.currentUser.lastName}`
        : ''
    },
    getImpactIconIdByType:
      (s: StateInterface) =>
      (type: OffsetType): ImpactIconId => {
        return (
          s.constants?.impactTypes?.find((impactType) => impactType.type === type)?.iconId ||
          '#trees-icon'
        )
      },
    getTotalPlasticForTeamMembers: (s: StateInterface): number => {
      const plasticMultipliers: TeamMemberMultiplier =
        s.constants.impactTypes.find(({ type }) => type === 'plastic')?.teamMemberMultiplier ||
        ({} as TeamMemberMultiplier)
      const standard = new Decimal(s.account.employeePlans.standard || 0).times(
        plasticMultipliers.standard || 1,
      )
      const superior = new Decimal(s.account.employeePlans.superior || 0).times(
        plasticMultipliers.superior || 1,
      )
      const premium = new Decimal(s.account.employeePlans.premium || 0).times(
        plasticMultipliers.premium || 1,
      )
      const plasticHero = new Decimal(s.account.employeePlanAddons.plasticHero || 0).times(
        plasticMultipliers.addon || 1,
      )
      return standard.plus(superior).plus(premium).plus(plasticHero).toDP(0).toNumber()
    },
    getTotalCarbonForTeamMembers: (s: StateInterface): number => {
      const carbonMultipliers: TeamMemberMultiplier =
        s.constants.impactTypes.find(({ type }) => type === 'carbon')?.teamMemberMultiplier ||
        ({} as TeamMemberMultiplier)
      const standard = new Decimal(s.account.employeePlans.standard || 0).times(
        carbonMultipliers.standard || 1,
      )
      const superior = new Decimal(s.account.employeePlans.superior || 0).times(
        carbonMultipliers.superior || 1,
      )
      const premium = new Decimal(s.account.employeePlans.premium || 0).times(
        carbonMultipliers.premium || 1,
      )
      return standard.plus(superior).plus(premium).toDP(3).toNumber()
    },
    getTotalTreesForTeamMembers: (s: StateInterface): number => {
      const treesMultipliers: TeamMemberMultiplier =
        s.constants.impactTypes.find(({ type }) => type === 'trees')?.teamMemberMultiplier ||
        ({} as TeamMemberMultiplier)
      return new Decimal(s.account.employeePlanAddons.treePlanter || 0)
        .times(treesMultipliers.addon || 1)
        .toDP(0)
        .toNumber()
    },
    getTotalImpactForTeamMembersByType:
      (s: StateInterface, getters) =>
      (type: OffsetType): number => {
        switch (type) {
          case 'carbon':
            return getters.getTotalCarbonForTeamMembers
          case 'plastic':
            return getters.getTotalPlasticForTeamMembers
          case 'trees':
            return getters.getTotalTreesForTeamMembers
          case 'kelp':
            return 0
          case 'water':
            return 0
          default: {
            const exhaustiveCheck: never = type
            throw new Error(exhaustiveCheck)
          }
        }
      },
    getTotalImpactForTeamMembers: (
      s: StateInterface,
      getters,
    ): { type: OffsetType; amount: number }[] => {
      return SUBSCRIPTION_OFFSET_TYPES.map((type) => {
        return {
          type,
          amount: getters.getTotalImpactForTeamMembersByType(type),
        }
      })
    },
    getPlasticAchievement: (s: StateInterface, getters): AchievementData => {
      const plastic = getters.getTotalImpactByType('plastic')
      const plasticOffset: AchievementData = {
        level: 0,
        text: '',
        value: 0,
        maxLevel: 8,
        type: 'plastic',
      }
      const numberFormat = new Intl.NumberFormat('en-US')

      if (plastic < 500) {
        plasticOffset.level = 0

        plasticOffset.value = (plastic / 500) * 100
      } else if (plastic >= 500 && plastic < 1000) {
        plasticOffset.level = 1
        plasticOffset.value = (plastic / 1000) * 100
      } else if (plastic >= 1000 && plastic < 5000) {
        plasticOffset.level = 2
        plasticOffset.value = (plastic / 5000) * 100
      } else if (plastic >= 5000 && plastic < 10000) {
        plasticOffset.level = 3
        plasticOffset.value = (plastic / 10000) * 100
      } else if (plastic >= 10000 && plastic < 50000) {
        plasticOffset.level = 4
        plasticOffset.value = (plastic / 50000) * 100
      } else if (plastic >= 50000 && plastic < 100000) {
        plasticOffset.level = 5
        plasticOffset.value = (plastic / 100000) * 100
      } else if (plastic >= 100000 && plastic < 500000) {
        plasticOffset.level = 6
        plasticOffset.value = (plastic / 500000) * 100
      } else if (plastic >= 500000 && plastic < 1000000) {
        plasticOffset.level = 7
        plasticOffset.value = (plastic / 1000000) * 100
      } else {
        plasticOffset.level = 8
        plasticOffset.value = 100
      }
      plasticOffset.text = VueI18n.global.t(`plastic.achievements_level_${plasticOffset.level}`, {
        current: numberFormat.format(plastic),
        flavor: VueI18n.global.t('plastic.achievements_flavor'),
      })
      return plasticOffset
    },
    getCarbonAchievement: (s: StateInterface, getters): AchievementData => {
      const CO2 =
        Math.round((getters.getTotalImpactByType('carbon') + Number.EPSILON) * 1000) / 1000
      const carbonOffset: AchievementData = {
        level: 0,
        text: '',
        value: 0,
        maxLevel: 9,
        type: 'carbon',
      }
      const numberFormat = new Intl.NumberFormat('en-US')

      if (CO2 < 10) {
        carbonOffset.level = 0
        carbonOffset.value = (CO2 / 10) * 100
      } else if (CO2 >= 10 && CO2 < 20) {
        carbonOffset.level = 1
        carbonOffset.value = (CO2 / 20) * 100
      } else if (CO2 >= 20 && CO2 < 50) {
        carbonOffset.level = 2
        carbonOffset.value = (CO2 / 50) * 100
      } else if (CO2 >= 50 && CO2 < 100) {
        carbonOffset.level = 3
        carbonOffset.value = (CO2 / 100) * 100
      } else if (CO2 >= 100 && CO2 < 500) {
        carbonOffset.level = 4
        carbonOffset.value = (CO2 / 500) * 100
      } else if (CO2 >= 500 && CO2 < 1000) {
        carbonOffset.level = 5
        carbonOffset.value = (CO2 / 1000) * 100
      } else if (CO2 >= 1000 && CO2 < 2000) {
        carbonOffset.level = 6
        carbonOffset.value = (CO2 / 2000) * 100
      } else if (CO2 >= 2000 && CO2 < 5000) {
        carbonOffset.level = 7
        carbonOffset.value = (CO2 / 5000) * 100
      } else if (CO2 >= 5000 && CO2 < 10000) {
        carbonOffset.level = 8
        carbonOffset.value = (CO2 / 10000) * 100
      } else {
        carbonOffset.level = 9
        carbonOffset.value = 100
      }
      carbonOffset.text = VueI18n.global.t(`carbon.achievements_level_${carbonOffset.level}`, {
        current: numberFormat.format(CO2),
        flavor: VueI18n.global.t('carbon.achievements_flavor'),
      })
      return carbonOffset
    },
    getTreesAchievement: (s: StateInterface, getters): AchievementData => {
      const trees = getters.getTotalImpactByType('trees')
      const treesOffset: AchievementData = {
        level: 0,
        text: '',
        value: 0,
        maxLevel: 8,
        type: 'trees',
      }
      const numberFormat = new Intl.NumberFormat('en-US')
      if (trees < 100) {
        treesOffset.level = 0
        treesOffset.value = (trees / 100) * 100
      } else if (trees >= 100 && trees < 500) {
        treesOffset.level = 1
        treesOffset.value = (trees / 500) * 100
      } else if (trees >= 500 && trees < 1000) {
        treesOffset.level = 2
        treesOffset.value = (trees / 1000) * 100
      } else if (trees >= 1000 && trees < 2000) {
        treesOffset.level = 3
        treesOffset.value = (trees / 2000) * 100
      } else if (trees >= 2000 && trees < 5000) {
        treesOffset.level = 4
        treesOffset.value = (trees / 5000) * 100
      } else if (trees >= 5000 && trees < 10000) {
        treesOffset.level = 5
        treesOffset.value = (trees / 10000) * 100
      } else if (trees >= 10000 && trees < 50000) {
        treesOffset.level = 6
        treesOffset.value = (trees / 50000) * 100
      } else if (trees >= 50000 && trees < 100000) {
        treesOffset.level = 7
        treesOffset.value = (trees / 100000) * 100
      } else {
        treesOffset.level = 8
        treesOffset.value = 100
      }
      treesOffset.text = VueI18n.global.t(`trees.achievements_level_${treesOffset.level}`, {
        current: numberFormat.format(trees),
        flavor: VueI18n.global.t('trees.achievements_flavor'),
      })

      return treesOffset
    },
    getKelpAchievement: (s: StateInterface, getters): AchievementData => {
      const kelp = getters.getTotalImpactByType('kelp')
      const kelpOffset: AchievementData = {
        level: 0,
        text: '',
        value: 0,
        maxLevel: 8,
        type: 'kelp',
      }
      const numberFormat = new Intl.NumberFormat('en-US')
      if (kelp < 100) {
        kelpOffset.level = 0
        kelpOffset.value = (kelp / 100) * 100
      } else if (kelp >= 100 && kelp < 500) {
        kelpOffset.level = 1
        kelpOffset.value = (kelp / 500) * 100
      } else if (kelp >= 500 && kelp < 1000) {
        kelpOffset.level = 2
        kelpOffset.value = (kelp / 1000) * 100
      } else if (kelp >= 1000 && kelp < 2000) {
        kelpOffset.level = 3
        kelpOffset.value = (kelp / 2000) * 100
      } else if (kelp >= 2000 && kelp < 5000) {
        kelpOffset.level = 4
        kelpOffset.value = (kelp / 5000) * 100
      } else if (kelp >= 5000 && kelp < 10000) {
        kelpOffset.level = 5
        kelpOffset.value = (kelp / 10000) * 100
      } else if (kelp >= 10000 && kelp < 50000) {
        kelpOffset.level = 6
        kelpOffset.value = (kelp / 50000) * 100
      } else if (kelp >= 50000 && kelp < 100000) {
        kelpOffset.level = 7
        kelpOffset.value = (kelp / 100000) * 100
      } else {
        kelpOffset.level = 8
        kelpOffset.value = 100
      }
      kelpOffset.text = VueI18n.global.t(`kelp.achievements_level_${kelpOffset.level}`, {
        current: numberFormat.format(kelp),
        flavor: VueI18n.global.t('kelp.achievements_flavor'),
      })

      return kelpOffset
    },
    getWaterAchievement: (s: StateInterface, getters): AchievementData => {
      const water = getters.getTotalImpactByType('water')
      const waterOffset: AchievementData = {
        level: 0,
        text: '',
        value: 0,
        maxLevel: 8,
        type: 'water',
      }
      const numberFormat = new Intl.NumberFormat('en-US')
      if (water < 100) {
        waterOffset.level = 0
        waterOffset.value = (water / 100) * 100
      } else if (water >= 100 && water < 500) {
        waterOffset.level = 1
        waterOffset.value = (water / 500) * 100
      } else if (water >= 500 && water < 1000) {
        waterOffset.level = 2
        waterOffset.value = (water / 1000) * 100
      } else if (water >= 1000 && water < 2000) {
        waterOffset.level = 3
        waterOffset.value = (water / 2000) * 100
      } else if (water >= 2000 && water < 5000) {
        waterOffset.level = 4
        waterOffset.value = (water / 5000) * 100
      } else if (water >= 5000 && water < 10000) {
        waterOffset.level = 5
        waterOffset.value = (water / 10000) * 100
      } else if (water >= 10000 && water < 50000) {
        waterOffset.level = 6
        waterOffset.value = (water / 50000) * 100
      } else if (water >= 50000 && water < 100000) {
        waterOffset.level = 7
        waterOffset.value = (water / 100000) * 100
      } else {
        waterOffset.level = 8
        waterOffset.value = 100
      }
      waterOffset.text = VueI18n.global.t(`water.achievements_level_${waterOffset.level}`, {
        current: numberFormat.format(water),
        flavor: VueI18n.global.t('water.achievements_flavor'),
      })

      return waterOffset
    },
    getAchievementDataByOffsetType:
      (s: StateInterface, getters) =>
      (type: OffsetType): AchievementData => {
        switch (type) {
          case 'carbon':
            return getters.getCarbonAchievement
          case 'plastic':
            return getters.getPlasticAchievement
          case 'trees':
            return getters.getTreesAchievement
          case 'kelp':
            return getters.getKelpAchievement
          case 'water':
            return getters.getWaterAchievement
          default: {
            const exhaustiveCheck: never = type
            throw new Error(exhaustiveCheck)
          }
        }
      },
    getAchievementData: (s: StateInterface, getters): AchievementData[] => {
      return OFFSET_TYPES.map((type) => {
        return getters.getAchievementDataByOffsetType(type)
      })
    },
    getReferralAmountByOffsetType:
      () =>
      (type: OffsetType): number => {
        return (
          constants.impactTypes.find((impactType) => impactType.type === type)?.referralAmount || 0
        )
      },
    getTotalEmployees: (s: StateInterface): number => {
      return (
        (s.account.employeePlans.standard || 0) +
        (s.account.employeePlans.superior || 0) +
        (s.account.employeePlans.premium || 0)
      )
    },
    getTotalPendingEmployees: (s: StateInterface): number => {
      return (
        (s.account.temporaryUpgrades.standard || 0) +
        (s.account.temporaryUpgrades.superior || 0) +
        (s.account.temporaryUpgrades.premium || 0)
      )
    },
    getAccountId: (s: StateInterface): string => {
      return s.account.accountId
    },
    getAccount: (s: StateInterface): Account => {
      return s.account
    },
    getCurrentUser: (s: StateInterface): User => {
      return s.currentUser
    },
    getAuthUser: (s: StateInterface): AuthUser => {
      return s.authUser
    },
    getAuthUserId: (s: StateInterface): string => {
      return s.authUser?.uid
    },
    getUserLocale: (s: StateInterface): string => {
      return s.languageSettings.locale
    },
    getUserLanguage: (s: StateInterface): string => {
      return s.languageSettings.language
    },
    getLoading: (s: StateInterface): boolean => {
      return s.loading
    },
    getAlertTypes: (s: StateInterface): AlertType[] => {
      return s.alertTypes
    },
    getShowTopAlert: (s: StateInterface): boolean => {
      return s.showTopAlert
    },
    getFundingEndsDate: (s: StateInterface): string => {
      return s.fundingEndsDate
    },
    getRelationId: (s: StateInterface): string => {
      return s.relationId
    },
    getPasswordReset: (s: StateInterface): boolean => {
      return s.passwordReset
    },
    getPasswordResetError: (s: StateInterface): boolean => {
      return s.passwordResetError
    },
    getLoginError: (s: StateInterface): boolean => {
      return s.loginError
    },
    getRelationAllowanceAmount: (s: StateInterface): number => {
      return s.relationAllowanceAmount
    },
    getStripeURLs: (s: StateInterface): StripeURLs => {
      return s.stripeURLs
    },
    getBurgerMenuOpen: (s: StateInterface): boolean => {
      return s.burgerMenuOpen
    },
    getAnalyticsDateRange(s: StateInterface): Date[] {
      return s.analyticsDateRange
    },
    getEmailSyncAlertDismissed(s: StateInterface): boolean {
      return s.isEmailSyncAlertDismissed
    },
    getTopStats(s: StateInterface): TopStatData {
      const impactActionImpact = ['business', 'ecommerce'].includes(s.account?.accountType)
        ? []
        : s.account?.impactActionImpact || []
      const totalImpacts = [
        ...(s.account?.impacts || []),
        ...(s.account?.relationImpact || []),
        ...(s.account?.transferredImpacts || []),
        ...impactActionImpact,
      ]
      const impacts: TotalImpact[] = OFFSET_TYPES.map((type) => {
        const amount =
          totalImpacts
            .filter((impact) => impact.type === type)
            .reduce((acc, impact) => new Decimal(acc).add(impact.amount).toNumber(), 0) || 0
        return { type, amount }
      })

      const date1 = new Date()
      const date2 = s.account?.signupDate ? new Date(s.account?.signupDate) : new Date()

      let difference = (date2.getTime() - date1.getTime()) / 1000
      difference /= 60 * 60 * 24 * 7 * 4

      const monthsEarthPositive = Math.abs(Math.round(difference))
      return { monthsEarthPositive, ...parseImpactsArrayToObject(impacts) }
    },
    getTransferredImpactByOffsetType:
      (s: StateInterface) =>
      (offsetType: OffsetType): number => {
        return s.account.transferredImpacts.find(({ type }) => type === offsetType)?.amount || 0
      },
    getRelationImpactByOffsetType:
      (s: StateInterface) =>
      (offsetType: OffsetType): number => {
        if (s.account.relationImpact) {
          return s.account.relationImpact.find(({ type }) => type === offsetType)?.amount || 0
        }
        return 0
      },
    getImpactActionImpactByOffsetType:
      (s: StateInterface) =>
      (offsetType: OffsetType): number => {
        if (s.account.impactActionImpact) {
          return s.account.impactActionImpact.find(({ type }) => type === offsetType)?.amount || 0
        }
        return 0
      },
  },
  modules: {
    featureSettingsModule,
    subscriptionsModule,
    featureFlagsModule,
    customerEngagementModule,
    onboardingModule,
    checklistModule,
    usersModule,
    dialogModule,
    integrationsModule,
    bankAccountsModule,
    apiUsageModule,
    projectsModule,
    cartModule,
    formQuestionModule,
    notification: notificationModule,
  },
  plugins: [
    new VuexPersistence({
      key: getLocalStorageKey(),
    }).plugin,
  ],
})
