import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65976014"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "welcome-view" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "header-content" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "welcome-section"
}
const _hoisted_6 = { class: "company-name" }
const _hoisted_7 = { class: "welcome-text" }
const _hoisted_8 = { class: "welcome-section" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "welcome-text tackle_text" }
const _hoisted_11 = { class: "tackle-content-wrapper" }
const _hoisted_12 = ["src"]
const _hoisted_13 = {
  key: 1,
  class: "phone-image-wrapper"
}
const _hoisted_14 = { class: "phone-background" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "list-wrapper" }
const _hoisted_17 = { class: "check-list" }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = { class: "welcome-section full-width" }
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = { class: "project-image-list" }
const _hoisted_25 = ["src", "alt"]
const _hoisted_26 = { class: "carousel-wrapper" }
const _hoisted_27 = { class: "carousel-item-content-wrapper" }
const _hoisted_28 = ["src"]
const _hoisted_29 = { class: "carousel-item-text-wrapper" }
const _hoisted_30 = { class: "carousel-title" }
const _hoisted_31 = { class: "carousel-text" }
const _hoisted_32 = {
  key: 0,
  class: "carousel-impact-card"
}
const _hoisted_33 = { class: "carousel-impact-card-icon" }
const _hoisted_34 = ["href"]
const _hoisted_35 = ["innerHTML"]
const _hoisted_36 = { class: "welcome-section" }
const _hoisted_37 = { class: "welcome-title" }
const _hoisted_38 = { class: "impact-cards-wrapper" }
const _hoisted_39 = { class: "impact-card-icon" }
const _hoisted_40 = ["href"]
const _hoisted_41 = { class: "impact-card-title" }
const _hoisted_42 = { class: "impact-card-text" }
const _hoisted_43 = { class: "welcome-section full-width" }
const _hoisted_44 = { class: "activation-call" }
const _hoisted_45 = { class: "activation-call-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_ImpactCards = _resolveComponent("ImpactCards")!
  const _component_v_carousel_item = _resolveComponent("v-carousel-item")!
  const _component_v_carousel = _resolveComponent("v-carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          alt: "greenspark logo",
          height: "32",
          width: "160",
          class: "greenspark-logo",
          src: require('@/assets/greenspark-logo.svg')
        }, null, 8, _hoisted_4),
        _createVNode(_component_router_link, {
          class: "activate-link",
          to: { path: '/b/signup' },
          replace: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('activate_dashboard')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("main", null, [
      (_ctx.showImpactCards)
        ? (_openBlock(), _createElementBlock("section", _hoisted_5, [
            _createVNode(_component_i18n_t, {
              keypath: "WelcomeView.welcome_title",
              tag: "h1",
              class: "welcome-title main"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t(_ctx.customer.impactActionType.toLowerCase())) + " ", 1),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('companyName', { companyName: _ctx.customer.sellerCompanyName })), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ImpactCards, {
              impacts: _ctx.customer.impacts,
              theme: "light",
              class: "welcome-view-impact-cards"
            }, null, 8, ["impacts"]),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t('welcome_text')), 1),
            _createVNode(_component_router_link, {
              class: "activate-link",
              to: { path: '/b/signup' },
              replace: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('activate_dashboard')), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("section", _hoisted_8, [
        _createElementVNode("h2", {
          class: "welcome-title",
          innerHTML: _ctx.t('tackle_climate_change_title')
        }, null, 8, _hoisted_9),
        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.t('tackle_climate_change_text')), 1),
        _createElementVNode("div", _hoisted_11, [
          (_ctx.isMobile)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: require('@/assets/images/b2b2c-welcome/b2b2c_iphone.png'),
                alt: "example iphone",
                class: "b2b2c_iphone",
                loading: "lazy"
              }, null, 8, _hoisted_12))
            : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  (_ctx.windowWidth > 660)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: require('@/assets/images/b2b2c-welcome/phone-gif.gif'),
                        alt: "example iphone",
                        class: "b2b2c_iphone",
                        loading: "lazy"
                      }, null, 8, _hoisted_15))
                    : _createCommentVNode("", true)
                ])
              ])),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("ul", _hoisted_17, [
              _createElementVNode("li", {
                class: "check-list-item",
                innerHTML: _ctx.t('checklist_text_1')
              }, null, 8, _hoisted_18),
              _createElementVNode("li", {
                class: "check-list-item",
                innerHTML: _ctx.t('checklist_text_2')
              }, null, 8, _hoisted_19),
              _createElementVNode("li", {
                class: "check-list-item",
                innerHTML: _ctx.t('checklist_text_3')
              }, null, 8, _hoisted_20),
              _createElementVNode("li", {
                class: "check-list-item",
                innerHTML: _ctx.t('checklist_text_4')
              }, null, 8, _hoisted_21)
            ]),
            _createVNode(_component_router_link, {
              class: "activate-link",
              to: { path: '/b/signup' },
              replace: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('activate_dashboard')), 1)
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_22, [
        _createElementVNode("h2", {
          class: "welcome-title",
          innerHTML: _ctx.t('support_title')
        }, null, 8, _hoisted_23),
        _createElementVNode("div", _hoisted_24, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partnerLogos, ({ logo, name }) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "project-link",
              key: name
            }, [
              _createElementVNode("img", {
                src: logo,
                alt: name,
                class: "project-image"
              }, null, 8, _hoisted_25)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createVNode(_component_v_carousel, {
            "model-value": "0",
            continuous: "",
            "show-arrows": "",
            "hide-delimiter-background": "",
            height: "100%"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
                return (_openBlock(), _createBlock(_component_v_carousel_item, {
                  key: project.projectId
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("img", {
                        src: project.imageUrl,
                        alt: "project image",
                        height: "492",
                        width: "760",
                        class: "carousel-item-image"
                      }, null, 8, _hoisted_28),
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("h4", _hoisted_30, _toDisplayString(project.type === 'carbon' ? _ctx.$t('carbon.default_project_title') : project.name), 1),
                        _createElementVNode("p", _hoisted_31, _toDisplayString(project.type === 'carbon'
                        ? _ctx.$t('carbon.default_project_text')
                        : project.description), 1),
                        (_ctx.getImpactByProjectId(project.projectId))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                              (_openBlock(), _createElementBlock("svg", _hoisted_33, [
                                _createElementVNode("use", {
                                  href: _ctx.getImpactIconIdByType(project.type)
                                }, null, 8, _hoisted_34)
                              ])),
                              _createElementVNode("p", {
                                class: "impact-card-text mb-0",
                                innerHTML: 
                        _ctx.$t(`${project.type}.community_label`, {
                          amount: _ctx.formatNumberByLocale(
                            _ctx.getImpactByProjectId(project.projectId) ?? 0,
                          ),
                        })
                      
                              }, null, 8, _hoisted_35)
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("section", _hoisted_36, [
        _createElementVNode("h2", _hoisted_37, _toDisplayString(_ctx.t('make_difference_title')), 1),
        _createElementVNode("div", _hoisted_38, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.OFFSET_TYPES(), (type, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "impact-card"
            }, [
              (_openBlock(), _createElementBlock("svg", _hoisted_39, [
                _createElementVNode("use", {
                  href: _ctx.getImpactIconIdByType(type)
                }, null, 8, _hoisted_40)
              ])),
              _createElementVNode("h3", _hoisted_41, _toDisplayString(_ctx.$t(`${type}.ongoing_action`)), 1),
              _createElementVNode("p", _hoisted_42, _toDisplayString(_ctx.$t(`${type}.default_project_impact_description`)), 1)
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("section", _hoisted_43, [
        _createElementVNode("div", _hoisted_44, [
          _createElementVNode("h2", _hoisted_45, _toDisplayString(_ctx.t('track_impact_title')), 1),
          _createVNode(_component_router_link, {
            class: "activate-link",
            to: { path: '/b/signup' },
            replace: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('activate_dashboard')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}