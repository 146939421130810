import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74ae3dc3"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { "clip-path": "url(#clip0_4558_28673)" }
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    id: `public-map-pin-${_ctx.formatCountryName(_ctx.pin.country)}-${_ctx.pin.type}${_ctx.isStatic ? 'static' : ''}`,
    class: _normalizeClass([
      'public-map-pin',
      { 'is-active': _ctx.isActive },
      { 'is-animated': _ctx.isAnimated },
      { 'is-static': _ctx.isStatic },
    ]),
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 53 55",
    fill: "none"
  }, [
    _createElementVNode("g", _hoisted_2, [
      _createElementVNode("path", {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M26.5 3C35.6093 3 46 9.94588 46 23.1882C46 31.9976 39.5093 41.2871 26.5 51C13.4907 41.2871 7 31.9976 7 23.1882C7 9.94588 17.3907 3 26.5 3Z",
        fill: _ctx.colorSettings[_ctx.pin.type]
      }, null, 8, _hoisted_3),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentForImpactType)))
    ])
  ], 10, _hoisted_1))
}