<template>
  <li class="project-card-wrapper">
    <a :href="link" target="_blank" class="project-card-link">
      <img class="project-image" alt="supported-project-image" :src="image" />
      <p class="project-id">
        {{ titleId }}
      </p>
      <p class="project-title">
        {{ titleSub }}
      </p>
      <p class="project-learn-more">
        {{ t('learn') }}
      </p>
    </a>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProjectCard',
  computed: {
    titleId(): string {
      return `${this.title?.split(':')[0]}:`
    },
    titleSub(): string {
      return `${this.title?.split(':')[1]}`
    },
  },
  methods: {
    t(key: string) {
      return this.$t(`ProjectCard.${key}`)
    },
  },
  props: {
    title: {
      type: String,
    },
    image: {
      type: String,
    },
    link: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.project-card-wrapper {
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
  height: 600px;
  box-shadow: 0 4px 8px 0 transparent;
  width: 100%;
  overflow: hidden;
}

.project-card-wrapper:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.project-card-link {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding: 12px;
}

.project-card-wrapper:hover .project-learn-more {
  background-color: var(--ui-green);
  color: var(--ui-beige);
}

.project-image {
  width: 100%;
  object-fit: cover;
  height: 256px;
  border-radius: 8px;
}

.project-title {
  color: #212121;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  height: 135px;
  width: 100%;
}

.project-id {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin: 36px auto 12px 0;
  border-bottom: 2px solid #212121;
}

.project-learn-more {
  background-color: #f2ebdb;
  border-radius: 99px;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 700;
  margin-top: 28px;
  align-self: flex-start;
  transition: all 0.3s ease;
  margin-bottom: 0;
}

@media #{map-get($display-breakpoints, 'xs')} {
  .project-image {
    height: 300px;
  }

  .project-card-wrapper {
    height: auto;
  }

  .project-card-link {
    height: auto;
  }

  .project-title {
    font-size: 18px;
  }

  .project-id {
    font-size: 10px;
    margin: 14px auto 8px 0;
  }

  .project-learn-more {
    width: 100%;
    text-align: center;
    margin-top: 12px;
    font-size: 16px;
    padding: 10px 24px;
  }
}
</style>
