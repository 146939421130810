<template>
  <v-dialog v-model="dialog" width="700" :fullscreen="isMobile" scrollable>
    <template v-slot:activator="{ props }">
      <v-btn class="button" color="#3B755F" height="auto" variant="text" v-bind="props">
        {{ useButtonText }}
      </v-btn>
    </template>

    <div class="pop-up">
      <div class="close-wrapper" @click="dialog = false">
        <img
          style="width: 25px"
          class="close-icon"
          :src="require('@/assets/icons/close_icon.svg')"
          alt="close"
        />
      </div>

      <div class="pop-up-content-wrapper">
        <div v-if="shopifySteps" class="filter-container">
          <p class="compatible-text">{{ t('compatible_platforms') }}</p>
          <v-chip-group
            selected-class="active-filter"
            :model-value="activeFilter"
            @update:model-value="
              (v) => {
                if (!v) return
                activeFilter = v
              }
            "
            class="platform-filter-group"
          >
            <v-chip value="shopify" class="platform-filter" rounded="lg">{{ t('shopify') }}</v-chip>
            <v-chip value="widget-api" class="platform-filter" rounded="lg">{{
              t('widget_api')
            }}</v-chip>
            <v-chip v-if="isRebuyCompatible" value="rebuy" class="platform-filter" rounded="lg">{{
              t('rebuy_smart_cart')
            }}</v-chip>
          </v-chip-group>
        </div>

        <slot />

        <template v-if="shopifySteps && activeFilter === 'shopify'">
          <div class="overlay-text">
            {{ overlayText }}
          </div>

          <div class="how-it-works">
            <div class="hiw-title">
              {{ t('how_it_works') }}
            </div>
            <div class="number-explanation-wrapper">
              <div class="number">
                <CircleBackground class="circle-background" />
                <span>1</span>
              </div>
              <div class="explanation">
                {{ t('shopify_tutorial.step_1') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number">
                <CircleBackground class="circle-background" />
                <span>2</span>
              </div>
              <div class="explanation">
                {{ t('shopify_tutorial.step_2') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number"><CircleBackground class="circle-background" /><span>3</span></div>
              <div class="explanation">
                {{ t('shopify_tutorial.step_3') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number"><CircleBackground class="circle-background" /><span>4</span></div>
              <div class="explanation">
                {{ t('shopify_tutorial.step_4') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number"><CircleBackground class="circle-background" /><span>5</span></div>
              <div class="explanation">
                {{ t('shopify_tutorial.step_5') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number"><CircleBackground class="circle-background" /><span>6</span></div>
              <div class="explanation">
                {{ t('shopify_tutorial.step_6') }}
              </div>
            </div>
          </div>

          <v-select
            v-model="shopify"
            :items="displayUrls"
            label="Shop Name"
            variant="outlined"
            color="#3B755F"
            item-color="#3B755F"
            class="dropdown dropdown-block"
            style="height: 46px"
            hide-details
          />

          <div class="support">
            <span>{{ t('questions') }}</span>
            <i18n-t tag="span" keypath="Badge.help">
              <a
                href="https://help.shopify.com/en/manual/online-store/themes/theme-structure/extend/apps#app-blocks"
                target="_blank"
                class="green-text"
                >{{ t('help_read') }}</a
              >
              <button class="green-text" @click="openChat()">
                {{ t('help_chat') }}
              </button>
            </i18n-t>
          </div>

          <gs-button
            full-width
            capitalized
            :uppercased="false"
            size="large"
            type="primary-v2"
            target="_blank"
            :disabled="!buttonActive"
            :href="shopifyUrl"
          >
            {{ t('proceed_to_shopify') }}
          </gs-button>
        </template>
        <template v-if="!shopifySteps || activeFilter === 'widget-api'">
          <div class="overlay-text">
            {{ overlayText }}
          </div>

          <div class="how-it-works">
            <div class="hiw-title">
              {{ t('how_it_works') }}
            </div>
            <div class="number-explanation-wrapper">
              <div class="number"><CircleBackground class="circle-background" /><span>1</span></div>
              <div class="explanation">
                <i18n-t tag="span" keypath="Badge.api_tutorial.step_1">
                  <router-link to="/account?tab=apiKey">
                    {{ t('account_link') }}
                  </router-link>
                </i18n-t>
              </div>
            </div>
            <div class="number-explanation-wrapper">
              <div class="number"><CircleBackground class="circle-background" /><span>2</span></div>
              <div class="explanation">
                <i18n-t tag="span" keypath="Badge.api_tutorial.step_2">
                  <a :href="API_DOCUMENTATION_URL" target="_blank">
                    {{ t('documentation_link') }}
                  </a>
                </i18n-t>
              </div>
            </div>
            <div class="number-explanation-wrapper">
              <div class="number"><CircleBackground class="circle-background" /><span>3</span></div>
              <div class="explanation">
                {{ t('api_tutorial.step_3') }}
              </div>
            </div>
          </div>
          <div class="support">
            <span>{{ t('help_more') }}</span>
            <button class="green-text" @click="openChat()">
              {{ t('help_chat') }}
            </button>
          </div>
        </template>

        <template v-if="shopifySteps && activeFilter === 'rebuy'">
          <div class="how-it-works">
            <div class="hiw-title">
              {{ t('rebuy_tutorial.title') }}
            </div>
            <div class="number-explanation-wrapper" v-if="!widgetApiKey">
              <div class="number">
                <CircleBackground class="circle-background" />
                <span>1</span>
              </div>
              <div class="explanation">
                {{ t('rebuy_tutorial.step_1') }}
                <router-link :to="{ name: 'Account', query: { tab: 'apiKey' } }">
                  {{ t('rebuy_tutorial.click_here') }}
                  <v-icon color="#0017B4" size="20px">mdi-open-in-new</v-icon>
                </router-link>
              </div>
            </div>

            <div class="number-explanation-wrapper step-with-input">
              <div class="number">
                <CircleBackground class="circle-background" />
                <span>{{ widgetApiKey ? '1' : '2' }}</span>
              </div>
              <div class="explanation">
                {{ t('rebuy_tutorial.step_2') }}
              </div>
            </div>

            <v-select
              v-model="shopify"
              :items="displayUrls"
              density="compact"
              variant="outlined"
              color="#3B755F"
              item-color="#3B755F"
              class="dropdown dropdown-block"
              hide-details
            />

            <div class="number-explanation-wrapper">
              <div class="number">
                <CircleBackground class="circle-background" /><span>{{
                  widgetApiKey ? '2' : '3'
                }}</span>
              </div>
              <div class="explanation">
                {{ t('rebuy_tutorial.step_3') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number">
                <CircleBackground class="circle-background" /><span>{{
                  widgetApiKey ? '3' : '4'
                }}</span>
              </div>
              <div class="explanation">
                {{ t('rebuy_tutorial.step_4') }}
                <a :href="rebuyInstallationGuideUrl" target="_blank">
                  {{ t('rebuy_tutorial.installation_guide') }}
                  <v-icon color="#0017B4" size="20px">mdi-open-in-new</v-icon>
                </a>
              </div>
            </div>

            <label for="rebuy-key-copy" class="key-input-label">{{
              t('rebuy_tutorial.key_input_label')
            }}</label>
            <v-text-field
              id="rebuy-key-copy"
              class="rebuy-key-input"
              :model-value="rebuySmartCartAppKey"
              variant="outlined"
              hide-details
              :placeholder="t('rebuy_tutorial.key_input_placeholder')"
              readonly
            >
              <template v-slot:append-inner>
                <v-tooltip
                  :open-on-hover="false"
                  :open-on-click="true"
                  location="top"
                  max-width="480px"
                  close-delay="5000"
                  :text="
                    rebuySmartCartAppKey
                      ? t('copy_action_success_feedback')
                      : t('copy_action_error_feedback')
                  "
                >
                  <template v-slot:activator="{ props }">
                    <v-icon @click="copyRebuyKey" v-bind="props">mdi-content-copy</v-icon>
                  </template>
                </v-tooltip>
              </template>
            </v-text-field>
          </div>

          <gs-button
            full-width
            capitalized
            :uppercased="false"
            size="large"
            type="primary-v2"
            target="_blank"
            :disabled="!buttonActive"
            :href="rebuyUrl"
          >
            {{ t('go_to_rebuy') }}
          </gs-button>
        </template>
      </div>
    </div>
  </v-dialog>
</template>
<script lang="ts">
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import { Utils } from '@/helpers/mixins/utilsMixin'
import {
  API_DOCUMENTATION_URL,
  isDemoEnvironment,
  REBUY_URL,
  REBUY_INSTALLATION_GUIDE_URL,
} from '@/helpers/constants'
import CircleBackground from '@/components/icons/CircleBackground.vue'
import { defineComponent } from 'vue'

import type { ApiKeyResponse } from '@api/index'
import type { Integration } from '@/store/integrations'
import type { PropType } from 'vue'
import type { BrandColor, IntegrationPlatform, StoreIntegrationTrigger } from '@/helpers/interfaces'
import type { TranslateResult } from 'vue-i18n'

export type DialogMode = 'banner' | 'product' | 'widget'

export default defineComponent({
  name: 'WidgetDialog',
  mixins: [LoadCrispMixin, Utils],
  components: { CircleBackground },
  data() {
    return {
      dialog: false,
      loaded: false,
      shopify: '',
      buttonActive: false,
      windowWidth: 0,
      shopifySteps: false,
      API_DOCUMENTATION_URL: '',
      activeFilter: 'widget-api',
      rebuyUrl: REBUY_URL,
      rebuyInstallationGuideUrl: REBUY_INSTALLATION_GUIDE_URL,
    } as {
      dialog: boolean
      loaded: boolean
      shopify: string
      buttonActive: boolean
      windowWidth: number
      shopifySteps: boolean
      API_DOCUMENTATION_URL: string
      activeFilter: 'shopify' | 'widget-api' | 'rebuy'
      rebuyUrl: Readonly<string>
      rebuyInstallationGuideUrl: Readonly<string>
    }
  },
  computed: {
    useButtonText(): TranslateResult {
      switch (this.mode) {
        case 'banner':
          return this.t('use_banner')
        case 'product':
        case 'widget':
          return this.t('use_widget')
        default:
          return this.t('use_badge')
      }
    },
    overlayText(): TranslateResult {
      if (this.mode === 'banner') {
        return this.shopifySteps ? this.t('overlay_shopify_banner') : this.t('overlay_api_banner')
      } else if (this.mode === 'product') {
        return this.shopifySteps ? this.t('overlay_shopify_product') : this.t('overlay_api')
      } else {
        return this.shopifySteps ? this.t('overlay_shopify_block') : this.t('overlay_api')
      }
    },
    switchButtonText(): TranslateResult {
      return this.shopifySteps ? this.t('api_switch') : this.t('shopify_switch')
    },
    isShopifyIntegrated(): boolean {
      return isDemoEnvironment
        ? true
        : !!this.getIntegrationsByPlatform('shopify').length || this.account.userFromMarketplace
    },
    shopifyUrls(): string[] {
      return isDemoEnvironment
        ? [this.integrationList[0]?.name]
        : this.getIntegrationsByPlatform('shopify').map(({ name }) => name)
    },
    shopifyUrl(): string {
      return `https://${this.shopify}/admin/themes/current/editor`
    },
    displayUrls(): { value: string; title: string }[] {
      return this.shopifyUrls.map((item, index) => {
        return { title: this.shopifyUrls[index], value: this.shopifyUrls[index] }
      })
    },
    integrationList(): Integration[] {
      return this.$store.getters['getIntegrationList']
    },
    getIntegrationsByPlatform(): (platform: IntegrationPlatform) => Integration[] {
      return this.$store.getters['getIntegrationsByPlatform']
    },
    isRebuyCompatible(): boolean {
      if (!this.isRebuyEnabled) return false
      if (!this.type) return false
      return ['offsetPerCurrentCart'].includes(this.type)
    },
    isRebuyEnabled(): boolean {
      return this.$store.getters['getRebuyFeatureFlag']
    },
    rebuySmartCartAppKey(): string {
      if (!this.widgetApiKey || !this.color) return ''

      return btoa(
        JSON.stringify({
          apiKey: this.widgetApiKey.value,
          shopUniqueName: this.shopify,
          type: 'cart',
          widgetArgs: {
            version: 'v2',
            color: this.color,
            style: this.style,
            withPopup: true,
          },
        }),
      )
    },
  },
  async created() {
    window.addEventListener('resize', this.reportWindowSize)
    this.reportWindowSize()
    this.API_DOCUMENTATION_URL = API_DOCUMENTATION_URL
    this.shopifySteps = this.isShopifyIntegrated
    if (this.shopifySteps) this.activeFilter = 'shopify'
    this.shopify = this.displayUrls[0]?.value

    this.loaded = true
  },
  unmounted() {
    window.removeEventListener('resize', this.reportWindowSize)
  },
  methods: {
    t(key: string) {
      return this.$t(`Badge.${key}`)
    },
    reportWindowSize() {
      this.windowWidth = window.innerWidth
    },
    onShopifyChange() {
      this.buttonActive = this.shopify !== ''
    },
    copyRebuyKey() {
      if (!this.rebuySmartCartAppKey) return
      navigator.clipboard.writeText(this.rebuySmartCartAppKey)
    },
  },
  props: {
    mode: { type: String as PropType<DialogMode>, default: 'widget' },
    type: {
      type: String as PropType<StoreIntegrationTrigger | 'offsetPerCurrentCart' | 'banner'>,
      required: false,
    },
    widgetApiKey: {
      type: Object as PropType<ApiKeyResponse>,
      required: false,
    },
    color: {
      type: String as PropType<BrandColor>,
      required: false,
    },
    style: {
      type: String as PropType<'default' | 'simplified'>,
      required: false,
    },
  },
  watch: {
    shopify: [
      {
        handler: 'onShopifyChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.menu-wrapper {
  align-items: flex-start;
  display: flex;
  padding: 20px 0;
  width: 100%;
}

.badge-wrapper {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 260px;
  background-color: #f9f9f9 !important;
}

.widget-image-wrapper {
  width: 100%;
  height: 100px;
  background-size: contain;
  background-position: center;
}

.widget-small {
  width: 100%;
  height: auto;
}

.filter-container {
  width: 100%;
  margin-bottom: 16px;

  .compatible-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 4px;
  }

  .platform-filter-group {
    .platform-filter {
      padding: 8px 16px;
      height: auto;
      margin-right: 24px;

      &:deep(.v-chip__content) {
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
      }
    }

    .active-filter {
      background-color: var(--ui-green);
      color: white;
    }
  }
}

.button {
  margin-top: 18px;
  border-radius: 0;
  padding: 0 !important;
}

.button-text-send {
  color: #3b755f;
  font-weight: 600;
  font-size: 12px;
}

.pop-up {
  background: #f9f9f9;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  min-height: 100%;
}

.close-wrapper {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.widget-overlay {
  width: 70%;
}

.pop-up-content-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.overlay-text {
  text-align: center;
  color: #212121;
  font-size: 15px;
  max-width: 440px;
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
}

.code {
  resize: none;
  width: 95%;
  padding: 10px;
  background-color: rgb(235, 235, 235);
  border-color: rgb(140, 149, 158);
  color: #212121;
  font-size: 14px;
  margin-top: 15px;
  word-break: break-all;
}

.how-it-works {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
}

.hiw-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 24px;
}

.number-explanation-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &.step-with-input {
    margin-bottom: 8px;
  }
}

.number {
  min-width: 27px;
  min-height: 26px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ui-black);
  font-weight: 600;
  margin-right: 8px;

  > span {
    position: relative;
    z-index: 1;
  }

  .circle-background {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: auto;
  }
}

.explanation {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;

  a {
    font-weight: 700;
    color: var(--ui-dark-blue);
  }
}

.dropdown {
  width: 100%;
}

.dropdown-block {
  margin-bottom: 16px;
}

.copy-item-wrapper {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.key-input-label {
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 8px;
}

.rebuy-key-input:deep(input::placeholder) {
  opacity: 1;
}

.label {
  font-weight: bold;
  // margin-right: 10px;
  margin-bottom: -7px;
  // width: 100%;
}

.copy-inner {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.item-to-copy {
  padding: 5px 20px;
  background: #f2ebdb;
  height: 54px;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
  max-width: 100%;
  white-space: nowrap;
  width: 355px;
}

.switch {
  cursor: pointer;
  color: #3b755f;
  font-size: 12px;
  margin-top: 12px;
}

.switch:hover {
  opacity: 80;
}

.support {
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 24px;
}

.green-text {
  padding-left: 2px;
  color: #3b755f;
  text-decoration: underline;
  cursor: pointer;
}

.green-text:hover {
  opacity: 80;
}

.green-text.bottom {
  margin-top: 20px;
}

@media screen and (max-width: 420px) {
  .widget-image-wrapper {
    width: 100%;
  }
}
</style>
